import { ApiRecordset } from "./ApiRecordset.js";
import type { ApiClient } from "./ApiClient.js";
import { Role } from "@momentum/model";

export class RoleRecordset extends ApiRecordset<Role, "IDRole"> {
  constructor(client: ApiClient) {
    super({
      client,
      primaryKey: "IDRole",
      path: "role",
    });
  }
}

export class RoleService {
  constructor(private client: ApiClient) {}
  public newRecordset(): RoleRecordset {
    return new RoleRecordset(this.client);
  }
}
