import { ClockRecordEx, ClockRecord, DateRange } from "@momentum/model";
import { Ref } from "vue";
import { ApiRecordset } from "./ApiRecordset.js";
import type { ApiClient } from "./ApiClient.js";

export class ClockRecordRecordset extends ApiRecordset<
  ClockRecordEx,
  "IDClockRecord" | "User",
  ClockRecord
> {
  constructor(client: ApiClient, dateRange: Ref<DateRange>) {
    super({
      client,
      primaryKey: "IDClockRecord",
      path: "clockrecord",
      default: () => ({
        Timestamp: new Date(),
        User: undefined,
        RecordType: null,
        ScheduleEntry: null,
        ExternalRef: null,
        RecordSource: {
          IDClockRecordSource: 0,
          ClockRecordSourceName: "Manual",
        },
      }),
      unpack: (a) => {
        a.Timestamp = new Date(a.Timestamp);
        if (a.ScheduleEntry) {
          a.ScheduleEntry.DateInit = new Date(a.ScheduleEntry.DateInit);
        }
        return a;
      },
      pack: (i) => {
        if (!i.User) {
          throw "must select a user for the record";
        }
        return {
          IDClockRecord: i.IDClockRecord,
          Timestamp: i.Timestamp,
          IDUser: i.User.IDUser,
          ExternalRef: i.ExternalRef,
          IDClockRecordSource: i.RecordSource.IDClockRecordSource,
          IDScheduleEntry: i.ScheduleEntry?.IDScheduleEntry || null,
          RecordType: typeof i.RecordType === "undefined" ? null : i.RecordType,
        };
      },
      queryHook(request) {
        request.dateRange(dateRange.value);
      },
    });
  }
}

export class ClockRecordService {
  constructor(public readonly client: ApiClient) {}
  public autoassign() {
    return this.client.invoke(`clockrecord/autoassign`).post<{}, number>({});
  }
  public newRecordset(dateRange: Ref<DateRange>): ClockRecordRecordset {
    return new ClockRecordRecordset(this.client, dateRange);
  }
}
