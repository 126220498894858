import { Optional } from "@momentum/model";
import { FilterQuery } from "@momentum/common";
import { reactive, watch, WatchStopHandle } from "vue";

type PrimaryKeyType = string | number | Date;

export enum RecordStatus {
  Unmodified,
  Dirty,
  New,
  Paused = -1,
}

export type Record<T> = T & { __status: RecordStatus; __id?: PrimaryKeyType };
export interface QueryResponse<T> {
  items: T[];
  total?: number;
}

export interface RecordsetConfig<T extends Object, PK extends keyof T> {
  default?: () => Optional<T, PK>;
  new?: (item: Record<Optional<T, PK>>) => Promise<T>;
  update?: (item: Record<T>) => Promise<T>;
  remove?: (item: Record<T>) => Promise<void>;
  query?: (filter?: FilterQuery) => Promise<QueryResponse<T>> | Promise<T[]>;
  primaryKey: string;
}

export function resetRecord<T>(
  r: Record<T>,
  newData: T,
  newId?: PrimaryKeyType
) {
  r.__status = RecordStatus.Paused;
  Object.assign(r, newData);
  if (newId !== undefined) {
    r.__id = newId;
  }
  r.__status = RecordStatus.Unmodified;
}

export function copyRecord<T>(source: Record<T>): Record<T> {
  const target = Object.assign({}, source);
  target.__id = source.__id;
  target.__status = source.__status;
  return target;
}

export function newRecord<T>(
  item: T,
  id?: PrimaryKeyType,
  status = RecordStatus.New,
  change?: (newStatus: RecordStatus, item: Record<T>) => void
): Record<T> {
  const ritem: Record<T> = reactive(item as any);
  let stopWatching: WatchStopHandle = () => {};
  function installWatch() {
    stopWatching = watch(
      () => ritem,
      () => {
        ritem.__status = RecordStatus.Dirty;
      },
      { deep: true }
    );
  }
  Reflect.defineProperty(ritem, "__id", {
    enumerable: false,
    get() {
      return id;
    },
    set(newId: PrimaryKeyType) {
      id = newId;
    },
  });
  Reflect.defineProperty(ritem, "__status", {
    enumerable: false,
    get() {
      return status;
    },
    set(newStatus: RecordStatus) {
      if (newStatus === RecordStatus.Paused) {
        stopWatching();
        stopWatching = () => {};
      } else {
        if (status === RecordStatus.Paused) {
          installWatch();
        } else {
          if (
            newStatus == status ||
            (newStatus !== RecordStatus.Unmodified &&
              status !== RecordStatus.Unmodified)
          )
            return;
        }
        if (change) {
          change(newStatus, ritem);
        }
      }
      status = newStatus;
    },
  });
  installWatch();
  return ritem;
}

export function isRecord<T>(x: any): x is Record<T> {
  return "__id" in (x as any) && "__status" in (x as any);
}

export interface IReadonlyRecordset<T extends Object> {
  primaryKeyFieldName(): string;
  query(filter?: FilterQuery): Promise<void>;
  get values(): Record<T>[];
}

export type AnyRecord = Record<any>;
export type AnyRecordset = Recordset<any, "">;
export class Recordset<T extends Object, PK extends keyof T> {
  protected items: Record<T>[];
  private _modified = 0;
  private _total: number = 0;
  public lastQuery: FilterQuery | undefined;
  public config: RecordsetConfig<T, PK>;
  constructor(config: RecordsetConfig<T, PK>) {
    this.items = [];
    this.config = config;
  }
  public isNew(r: Record<T>) {
    return r.__status === RecordStatus.New;
  }

  private toRecord(item: T): Record<T> {
    return newRecord(
      item,
      this.getId(item),
      RecordStatus.Unmodified,
      (newStatus) =>
        (this._modified += newStatus == RecordStatus.Unmodified ? -1 : 1)
    );
  }

  private add(isNew: boolean, newItems: Optional<T, PK>[]) {
    return newItems.map((item) => {
      const ritem = this.toRecord(item as T);

      if (isNew) {
        ritem.__status = RecordStatus.New;
      }
      this.items.push(ritem as any);
      return ritem;
    });
  }

  public primaryKeyFieldName(): string {
    return this.config.primaryKey;
  }

  public load(items: T[], total?: number) {
    this.items = [];
    this._modified = 0;
    this.add(false, items);
    this._total = total === undefined ? this.items.length : total;
  }
  public push(...items: Optional<T, PK>[]) {
    return this.add(true, items);
  }

  public get total(): number {
    return this._total;
  }

  async remove(...items: Record<T>[]) {
    for (const item of items) {
      if (item.__status !== RecordStatus.New && this.config.remove) {
        await this.config.remove(item);
      }
      const newItems = this.items.filter((i) => item !== i);
      if (
        newItems.length < this.items.length &&
        item.__status !== RecordStatus.Unmodified
      ) {
        this._modified--;
      }
      this.items = newItems;
    }
  }

  public clear() {
    this.items = [];
  }

  public async query(filter?: FilterQuery) {
    if (this.config.query) {
      let result = await this.config.query(filter);
      if (result instanceof Array) {
        result = { items: result, total: result.length };
      }
      this.load(result.items, result.total);
    }
    this.lastQuery = filter;
  }
  public async requery() {
    return this.query(this.lastQuery);
  }

  public addNew(): Optional<T, PK> {
    const newItem = (this.config.default && this.config.default()) || ({} as T);
    return this.push(newItem)[0];
  }
  public get values() {
    return this.items;
  }
  public get modified() {
    return this._modified;
  }

  protected getId(item: T): PrimaryKeyType {
    return (
      (this.config.primaryKey && (item as any)[this.config.primaryKey]) ||
      undefined
    );
  }

  public async save() {
    for (const i of this.items) {
      if (i.__status == RecordStatus.New) {
        const newItem = this.config.new ? await this.config.new(i) : i;
        resetRecord(i, newItem, this.getId(newItem));
        continue;
      }
      if (i.__status == RecordStatus.Dirty) {
        if (this.config.update) {
          const updatedItem = await this.config.update(i);
          resetRecord(i, updatedItem, this.getId(updatedItem));
        } else i.__status = RecordStatus.Unmodified;
        continue;
      }
    }
  }
}
