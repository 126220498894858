import { asyncComputed, dateUtils } from "@momentum/common";
import { Holiday } from "@prisma/client";
import { Ref } from "vue";
import { ApiRecordset } from "./ApiRecordset.js";
import type { ApiClient } from "./ApiClient.js";

export class HolidayRecordset extends ApiRecordset<Holiday, "DateHoliday"> {
  constructor(client: ApiClient, dateRange: Ref<[Date, Date]>) {
    super({
      client,
      primaryKey: "DateHoliday",
      path: {
        query: () => `holiday`,
        new: (item) => `holiday`,
        update: (item) => `holiday/${(item.__id as Date).getTime()}`,
        remove: (item) => `holiday/${(item.__id as Date).getTime()}`,
      },
      queryHook(request) {
        const dateInit = dateRange.value[0] || new Date();
        const dateEnd = dateRange.value[1] || dateUtils.addDays(dateInit, 1);
        request.dateRange(dateInit, dateEnd);
      },
    });
  }
}

export type HolidayWithWeekend =
  | (Holiday & { weekend?: boolean; publicHoliday: boolean })
  | undefined;

export class HolidayRange {
  public readonly holidays: Ref<Map<number, Holiday> | undefined>;
  constructor(
    service: HolidayService,
    dateInit: Ref<Date>,
    dateEnd: Ref<Date>
  ) {
    this.holidays = asyncComputed(async () => {
      return service.getHolidays(dateInit.value, dateEnd.value);
    });
  }

  public get(dt: Date) {
    return this.holidays?.value?.get(dt.getTime());
  }

  public getWithWeekend(date: Date): HolidayWithWeekend {
    const h = this.get(date);
    const weekend = dateUtils.isWeekend(date);
    if (!h && weekend) {
      return {
        DateHoliday: date,
        HolidayName: "Festivo",
        weekend: true,
        publicHoliday: false,
      };
    }
    if (h) {
      return {
        ...h,
        weekend: weekend,
        publicHoliday: true,
      };
    }
    return undefined;
  }
}

export class HolidayService {
  constructor(private client: ApiClient) {}
  public async getHolidays(dateInit: Date, dateEnd: Date) {
    const result = await this.client
      .invoke("holiday")
      .dateRange(dateInit, dateEnd)
      .filter({ rows: 1000 })
      .getPaged<Holiday>();

    return new Map<number, Holiday>(
      result.data.map((h) => [h.DateHoliday.getTime(), h])
    );
  }
  public newRecordset(dateRange: Ref<[Date, Date]>): HolidayRecordset {
    return new HolidayRecordset(this.client, dateRange);
  }
  public newRange(dateInit: Ref<Date>, dateEnd: Ref<Date>): HolidayRange {
    return new HolidayRange(this, dateInit, dateEnd);
  }
}
