export const locale = {
  locale: "es-ES",
  dateFormat: "dd/mm/yy",
  startsWith: "Starts with",
  contains: "Contains",
  notContains: "Not contains",
  endsWith: "Ends with",
  equals: "Equals",
  notEquals: "Not equals",
  noFilter: "No Filter",
  lt: "Less than",
  lte: "Less than or equal to",
  gt: "Greater than",
  gte: "Greater than or equal to",
  dateIs: "Date is",
  dateIsNot: "Date is not",
  dateBefore: "Date is before",
  dateAfter: "Date is after",
  clear: "Clear",
  apply: "Apply",
  matchAll: "Match All",
  matchAny: "Match Any",
  addRule: "Add Rule",
  removeRule: "Remove Rule",
  accept: "Yes",
  reject: "No",
  choose: "Choose",
  upload: "Upload",
  cancel: "Cancel",
  dayNames: [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ],
  dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
  dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
  monthNames: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  monthNamesShort: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
  firstDayOfWeek: 1,
  today: "Hoy",
  weekHeader: "S",
  weak: "Weak",
  medium: "Medium",
  strong: "Strong",
  passwordPrompt: "Enter a password",
  emptyFilterMessage: "No results found",
  emptyMessage: "No available options",
};

import dateUtilsFactory from "./dateUtils.js";

export function dateUtilsF(firstDayOfWeek = locale.firstDayOfWeek) {
  return dateUtilsFactory(locale, firstDayOfWeek);
}

export const dateUtils = dateUtilsF();

const intlDateFormatter = Intl.DateTimeFormat(locale.locale);
const intlDateTimeFormatter = Intl.DateTimeFormat(locale.locale, {
  dateStyle: "full",
  timeStyle: "long",
});

const intlHourFormatter = Intl.NumberFormat("es-ES", {
  style: "unit",
  unit: "hour",
  maximumFractionDigits: 1,
});

const intlHourFormatterWithDecimals = Intl.NumberFormat("es-ES", {
  style: "unit",
  unit: "hour",
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
});

// Return an object to wrap calls to Intl.DateTimeFormat
// to avoid Safari/iOS quirk when using the formatter within
// Vue templates

export const dateFormatter = {
  format(date?: number | Date | undefined) {
    return intlDateFormatter.format(date);
  },
};

export const dateTimeFormatter = {
  format(date?: number | Date | undefined) {
    return intlDateTimeFormatter.format(date);
  },
};

export const hourFormatter = {
  format(value: number | bigint, forceDecimals = false, noSpacing = false) {
    const strnum = forceDecimals
      ? intlHourFormatterWithDecimals.format(value)
      : intlHourFormatter.format(value);
    if (noSpacing) return strnum.replace(" ", "");
    return strnum;
  },
};

export function formatDateRanges(dateRanges: [Date, Date][]): string {
  let result = "";
  let sameYear = true;
  let sameDay = false;
  let currentYear = 0;

  for (let range of dateRanges) {
    const [startDate, endDate] = range;
    if (currentYear === 0) {
      if (startDate.getFullYear() !== endDate.getFullYear()) {
        sameYear = false;
        break;
      }
      currentYear = startDate.getFullYear();
    } else if (
      currentYear !== startDate.getFullYear() ||
      currentYear !== endDate.getFullYear()
    ) {
      sameYear = false;
      break;
    }
  }
  dateRanges.forEach(([startDate, endDate], index) => {
    // Determine if start and end dates are the same day
    sameDay = startDate.toDateString() === endDate.toDateString();
    console.log(sameDay);

    // Format start and end dates
    const start = startDate.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: sameYear ? undefined : "2-digit",
    });
    const end = sameDay
      ? ""
      : endDate.toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: sameYear ? undefined : "2-digit",
        });
    // Append formatted date range to result string
    result += `${start}${end ? "—" + end : ""}`;

    // Append comma if not last date range
    if (index !== dateRanges.length - 1) {
      result += ", ";
    }
  });

  return result;
}
