<template>
  <slot :user="user"></slot>
  <span v-if="!hasSlot">{{
    (user && `${user.LastName}, ${user.FirstName}`) || ""
  }}</span>
</template>

<script lang="ts">
import { asyncComputed } from "@momentum/common";
import { defineComponent, toRefs } from "vue";
import { client } from "@/client";

export default defineComponent({
  props: {
    IDUser: { type: Number, required: false },
  },
  setup(props, { slots }) {
    const { IDUser } = toRefs(props);
    const user = asyncComputed(
      async () =>
        (IDUser && IDUser.value && client.user.getUserCached(IDUser.value)) || //TODO: Cleanup "prop && prop.value" type stuff. Since Vue 3.1, optional props always exist.
        undefined
    );

    return { user, hasSlot: !!slots.default };
  },
});
</script>

<style scoped></style>