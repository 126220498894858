import * as DB from "@prisma/client";
import { SpecialShiftCode, User } from "@prisma/client";
export { DB };

//********************************************* */
// Define Auxiliary Types

export type Optional<Type, Fields extends keyof Type> = {
  [Property in Fields]+?: Type[Property];
} & Omit<Type, Fields>;

// expands object types one level deep
export type Expand1<T> = T extends infer O ? { [K in keyof O]: O[K] } : never;

// expands object types recursively
export type Expand<T> = T extends object
  ? T extends Date
    ? Date
    : T extends infer O
    ? { [K in keyof O]: Expand<O[K]> }
    : never
  : T;

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

export interface ParamSerializer<T> {
  fromURL: (s: string | undefined) => T | undefined;
  toURL: (t: T | undefined) => string | undefined;
  isEqual?: (a: T, b: T) => boolean;
}

type ParamType<T> = ParamConstructor<T> | ParamConstructor<T>[];

type ParamConstructor<T = any> =
  | {
      new (...args: any[]): T & {};
    }
  | {
      (): T;
    }
  | ParamMethod<T>
  | ParamSerializer<T>;

type ParamMethod<T, TConstructor = any> = [T] extends [(...args: any) => any]
  ? {
      new (): TConstructor;
      (): T;
      readonly prototype: TConstructor;
    }
  : never;

export interface ParamOptions<T = any> {
  type: ParamType<T>;
  required?: boolean;
  default?: T | null | undefined | object;
}

type Instance<T = any> = T extends Number
  ? number
  : T extends Date
  ? Date
  : T extends DateConstructor
  ? Date
  : T extends String
  ? string
  : T extends Boolean
  ? boolean
  : T extends (x: any) => infer R
  ? R
  : T extends ParamOptions<infer P>
  ? P
  : never;

export interface ConstructorDictionary {
  [index: string]: ParamOptions | ParamType<any>;
}

export type DictionaryInstanceUndefined<T extends ConstructorDictionary> = {
  [k in keyof T]: T[k] extends {
    required: true;
  }
    ? Instance<T[k]>
    : Instance<T[k]> | undefined;
};

export type DictionaryInstance<T extends ConstructorDictionary> = {
  [k in keyof T]: Instance<T[k]>;
};

export type Union2Intersection<U> = (
  U extends any ? (k: U) => void : never
) extends (k: infer I) => void
  ? I
  : never;

export type Tuple2Union<T extends any[]> = T[number];

export type TupleToIntersection<T extends any[]> = Union2Intersection<
  Tuple2Union<T>
>;

export type Plain<T> = { [P in keyof T]?: T[P] };

export type Public<T> = { [K in keyof T]: T[K] };

// end auxiliary types
//********************************************* */

export type DateRange = [Date, Date];
export type OpenDateRange = [Date | null | undefined, Date | null | undefined];

export const DateRange = {
  toWhere(dateInit: Date, dateEnd: Date, fieldName: string) {
    return [
      {
        [fieldName]: {
          gte: dateInit,
        },
      },
      {
        [fieldName]: {
          lt: dateEnd,
        },
      },
    ];
  },
};

export type ScheduleEntryEx = DB.ScheduleEntry & {
  ClockIn?: ClockRecordEx | null;
  ClockOut?: ClockRecordEx | null;
  IDClockIn?: number | null;
  IDClockOut?: number | null;
};

export const ScheduleEntryEx = {
  toScheduleEntry(sx: ScheduleEntryEx): DB.ScheduleEntry {
    const sxc = Object.assign({}, sx);
    delete sxc.ClockIn;
    delete sxc.ClockOut;
    delete sxc.IDClockIn;
    delete sxc.IDClockOut;
    return sxc;
  },
};
export type TeamScheduleEntry = {
  user: DB.User;
  schedule: DB.ScheduleEntry[];
};
export interface TeamScheduleEntryEx {
  user: UserBasicInfo;
  schedule: ScheduleEntryClockEx[];
}

export interface ExtraTimeMode {
  label: string;
  title: string;
}
export const ExtraTimeModes: ExtraTimeMode[] = [
  { label: "N", title: "Normal" },
  { label: "E", title: "Extensión" },
  { label: "A", title: "Absentismo" },
  { label: "P", title: "Planificado" },
];

export const ExtraTimeModesMap = new Map(
  ExtraTimeModes.map((e) => [e.label, e])
);

export const ATTAINMENT_HIGH = 0.985;
export const ATTAINMENT_MEDIUM = 0.96;

export interface SyncInfo<T> {
  modified: T[];
  new: T[];
  deleted: number[];
}

export interface UserBasicInfo {
  IDUser: number;
  FirstName?: string;
  LastName?: string;
  UserName?: string;
}

export const UserBasicInfo = {
  format: (user: UserBasicInfo) =>
    (user &&
      user.FirstName &&
      user.LastName &&
      user.LastName + ", " + user.FirstName) ||
    "",
  select: {
    select: {
      IDUser: true,
      LastName: true,
      FirstName: true,
      UserName: true,
    },
  },
};

export type UserWrite = User & {
  AuthInfo: {
    IDRole: number;
  } | null;
};

export const UserWrite = {
  split(uw: UserWrite) {
    const authInfo = uw.AuthInfo;
    delete (uw as any).AuthInfo;
    const user: User = uw;
    return { user, authInfo };
  },
};

export type UserRead = UserWrite & {
  AuthInfo: {
    Role: {
      RoleName: string;
    };
  } | null;
};

export const UserRead = {
  include: {
    AuthInfo: {
      select: { IDRole: true, Role: { select: { RoleName: true } } },
    },
  },
};

export type TeamWrite = DB.Team;
export type TeamRead = DB.Team & { TeamCategory: DB.TeamCategory | null };
export const TeamRead = {
  include: { TeamCategory: true },
};

export interface ShiftInterval {
  Start: number;
  AttendanceTime: number;
  BreakTime: number;
}

export type SpecialShiftCodesMap = Map<string, SpecialShiftCode>;

export function isSpecialShiftCode(
  code: string,
  specialShiftCodes: SpecialShiftCodesMap
) {
  return specialShiftCodes.has(code);
}

export type Shift = Omit<DB.Shift, "Intervals" | "IDShift"> & {
  IDShift?: number;
  Intervals: ShiftInterval[];
};

export const Shift = {
  fromDB(dbs: DB.Shift): Shift {
    if (dbs.Intervals) dbs.Intervals = JSON.parse(dbs.Intervals);
    return dbs as any as Shift;
  },
  toDB(s: Shift): DB.Shift {
    s.Intervals = JSON.stringify(s.Intervals) as any;
    return s as any;
  },
  validCode(
    code: string | undefined | null,
    specialShiftCodes: SpecialShiftCodesMap
  ) {
    return code && !isSpecialShiftCode(code, specialShiftCodes);
  },
};

export type TeamGroup = Omit<DB.TeamGroup, "WeekNames"> & {
  WeekNames: string[];
};

export enum TeamRole {
  PEER = -1,
  EMPLOYEE = 1,
  SUPERVISOR = 2,
}

export const TeamRoleStr = {
  [TeamRole.PEER]: "Externo",
  [TeamRole.EMPLOYEE]: "Empleado",
  [TeamRole.SUPERVISOR]: "Supervisor",
};

export interface TeamMemberUser {
  User: UserBasicInfo;
  IDTeam: number;
  TeamGroup: TeamGroup | null;
  IDTeamGroup: number | null;
  IDTeamRole: number;
}

export const TeamGroup = {
  fromDB(tg: DB.TeamGroup | null) {
    if (tg === null) {
      return null;
    }
    const ntg: TeamGroup = { ...tg } as any;
    ntg.WeekNames = tg.WeekNames ? JSON.parse(tg.WeekNames) : [];
    return ntg;
  },
  toDB(ntg: TeamGroup | null | undefined) {
    if (!ntg) {
      return null;
    }
    const tg: DB.TeamGroup = { ...ntg } as any;
    tg.WeekNames = JSON.stringify(ntg.WeekNames);
    return tg;
  },
};

export type ShiftSequence = (string | null)[];
export type ScheduleTemplate = Omit<DB.ScheduleTemplate, "Sequence"> & {
  Sequence: ShiftSequence;
};

export const ScheduleTemplate = {
  fromDB(stdb: DB.ScheduleTemplate): ScheduleTemplate {
    const st: ScheduleTemplate = stdb as any as ScheduleTemplate;
    st.Sequence = JSON.parse(stdb.Sequence);
    if (!st.Sequence) {
      st.Sequence = [];
    }
    return st;
  },
  toDB(st: ScheduleTemplate): DB.ScheduleTemplate {
    const stdb = st as any as DB.ScheduleTemplate;
    stdb.Sequence = JSON.stringify(st.Sequence);
    return stdb;
  },
};

export type ScheduleEntryWithTeam = DB.ScheduleEntry & { Team: DB.Team | null };

export type ClockRecordEx = {
  IDClockRecord: number;
  Timestamp: Date;
  User: UserBasicInfo;
  ExternalRef: string | null;
  ScheduleEntry?: ScheduleEntryWithTeam | null;
  RecordType?: number | null;
  RecordSource: DB.ClockRecordSource;
};

export const ClockRecordEx = {
  select: {
    IDClockRecord: true,
    Timestamp: true,
    ExternalRef: true,
    ScheduleEntry: {
      include: {
        Team: true,
      },
    },
    RecordType: true,
    User: UserBasicInfo.select,
    RecordSource: true,
  },
};

export type ClockRecord = Expand1<
  Omit<Optional<DB.ClockRecord, "IDClockRecord" | "ExternalRef">, "Assigned">
>;

export type ScheduleEntryClockWithTeam = DB.ScheduleEntryClock & {
  Team: DB.Team | null;
};

export type ScheduleEntryClock = DB.ScheduleEntry & {
  IDClockIn?: number | null;
  IDClockOut?: number | null;
};

export enum RecordType {
  ClockIn = 1,
  ClockOut = 2,
}

export enum ClockRecordSourceTypes {
  MANUAL = 0,
  NFC = 1,
  EXTERNAL = 2,
}

export type ClockRecordWithRecordSource = ClockRecord & {
  RecordSource: DB.ClockRecordSource;
};

export const ClockRecordWithRecordSource_Select = {
  RecordSource: true,
  IDClockRecord: true,
  IDScheduleEntry: true,
  IDUser: true,
  Timestamp: true,
  RecordType: true,
  IDClockRecordSource: true,
};

export type ScheduleEntryClockEx = ScheduleEntryClock & {
  ClockIn?: ClockRecordWithRecordSource | null;
  ClockOut?: ClockRecordWithRecordSource | null;
};

export const ScheduleEntryClockEx_Include = {
  ClockIn: {
    select: ClockRecordWithRecordSource_Select,
  },
  ClockOut: {
    select: ClockRecordWithRecordSource_Select,
  },
};

export const ScheduleEntryClockEx = {
  toScheduleEntryClock(secx: ScheduleEntryClockEx) {
    const sec = { ...secx };
    delete sec.ClockIn;
    delete sec.ClockOut;
    return sec as ScheduleEntryClock;
  },
  toScheduleEntry(sx: ScheduleEntryClockEx): DB.ScheduleEntry {
    const sxc = Object.assign({}, sx);
    delete sxc.ClockIn;
    delete sxc.ClockOut;
    delete sxc.IDClockIn;
    delete sxc.IDClockOut;
    return sxc;
  },
  realAttendance(
    sx: ScheduleEntryClockEx,
    specialShiftCodes: SpecialShiftCodesMap
  ): number | undefined {
    if (specialShiftCodes.get(sx.Code)?.Zero === false)
      return sx.AttendanceTime;
    return (
      (sx.ClockIn &&
        sx.ClockOut &&
        (sx.ClockOut.Timestamp.getTime() - sx.ClockIn.Timestamp.getTime()) /
          1000) ||
      undefined
    );
  },
  realWorkTime(
    sx: ScheduleEntryClockEx,
    specialShiftCodes: SpecialShiftCodesMap
  ): number | undefined {
    const attendance = this.realAttendance(sx, specialShiftCodes);
    if (attendance !== undefined)
      return attendance > sx.BreakTime ? attendance - sx.BreakTime : 0;
  },
  plannedWorkTime(sx: ScheduleEntryClockEx) {
    const wt = sx.AttendanceTime - sx.BreakTime;
    return (wt > 0 && wt) || 0;
  },
  attainment(
    sx: ScheduleEntryClockEx,
    specialShiftCodes: SpecialShiftCodesMap
  ): number | undefined {
    const real = this.realWorkTime(sx, specialShiftCodes);
    const planned = this.plannedWorkTime(sx);
    if (real !== undefined && planned > 0) {
      return real / planned;
    }
  },
  extraTime(
    sx: ScheduleEntryClockEx,
    specialShiftCodes: SpecialShiftCodesMap
  ): number | undefined {
    if (sx.ExtraTimeMode == "N") return 0;
    const real = this.realWorkTime(sx, specialShiftCodes);
    if (real === undefined) return undefined;
    switch (sx.ExtraTimeMode) {
      case "E":
      case "A":
        return real - this.plannedWorkTime(sx);
      case "P":
        return real;
    }
    return undefined;
  },
  hasClockRecords(sx: ScheduleEntryClockEx | undefined): boolean {
    return !!(sx && sx.ClockIn && sx.ClockOut);
  },
  default(): Partial<ScheduleEntryClockEx> {
    return {
      IDScheduleEntry: undefined,
      IDUser: 0,
      IDLocation: null,
      IDTeam: null,
      AttendanceTime: 0,
      BreakTime: 0,
      Code: "??",
      ExtraTimeMode: "N",
      Comment: null,
    };
  },
};

export type NewClockRecord = Optional<
  DB.ClockRecord,
  "IDClockRecord" | "IDScheduleEntry" | "ExternalRef"
>;

export type NewScheduleEntryClockEx = Optional<
  ScheduleEntryClock,
  "IDScheduleEntry"
> & {
  ClockIn?: NewClockRecord | null;
  ClockOut?: NewClockRecord | null;
};

export enum Permission {
  ADMIN,
  MY_SCHEDULE,
  LOGIN,
  UPLOAD_CLOCKRECORD,
  READ_ALL_SCHEDULES,
  WRITE_TEAM_SCHEDULE,
  READ_ALL_CLOCKRECORDS,
  UPDATE_CLOCKRECORD,
  CREATE_CLOCKRECORD,
  DELETE_CLOCKRECORD,
  MUST_CHANGE_PASSWORD,
  READ_SUPERVISED_TEAM_CLOCKRECORDS,
  READ_DAY_ENTRIES_REPORT,
  READ_SPECIFIC_USER,
}

export namespace Permission {
  export function toString(perms: Permission[]) {
    return perms.map((p) => Permission[p]).join(",");
  }
}

export interface Role {
  IDRole: number;
  RoleName: string;
  Permissions: Permission[];
}

export const Role = {
  fromDB(r: DB.Role): Role {
    const sp = JSON.parse(r.Permissions) as string[];
    const Permissions = sp.map(
      (s) => Permission[s as any] as any as Permission
    );
    return { ...r, Permissions };
  },
  toDB(R: Role): DB.Role {
    const Permissions = JSON.stringify(R.Permissions.map((p) => Permission[p]));
    return { ...R, Permissions };
  },
};

export enum TagType {
  CLOCKIN = 0,
  CHECKPOINT = 1,
}

export interface NFCAuthInfo {
  uid: string;
  ctr: string;
  cmac: string;
}

export interface NFCTagBasicInfo {
  IDTag: string;
  TagName: string | undefined | null;
}
export interface NFCClockin {
  clockRecord: ClockRecord;
  tagInfo: NFCTagBasicInfo | null | undefined;
}

export type ExtraTimeReportItem = {
  IDUser: number;
  LastName: string;
  FirstName: string;
  IDTeam: number | null;
  TeamName: string | undefined;
  IDTeamCategory: number | undefined;
  TeamCategoryName: string | undefined;
  ExtraTimeMode: string;
  Dates: DateRange[];
  ExtraTime: number;
};

export type DayEntryReportItem = {
  IDUser: number;
  LastName: string;
  FirstName: string;
  IDTeam: number | null;
  TeamName: string | undefined;
  IDTeamCategory: number | undefined;
  TeamCategoryName: string | undefined;
  ExtraTimeMode: string;
  DateInit: Date;
  DateEnd: Date;
  IsHoliday: boolean;
  Code: string;
  AttendanceTime: number;
  BreakTime: number;
  PlannedWorkTime: number;
  ClockIn: Date | null;
  ClockOut: Date | null;
  RealWorkTime: number;
  ExtraTime: number;
};
