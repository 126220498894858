// original source: https://github.com/angus-c/just/tree/master/packages/collection-clone

export function clone<T extends any>(obj: T): T {
  if (typeof obj == "function") {
    return obj;
  }
  const result: any = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    // include prototype properties
    const value: any = obj[key];
    const type = {}.toString.call(value).slice(8, -1);
    if (type == "Array" || type == "Object") {
      result[key] = clone(value);
    } else if (type == "Date") {
      result[key] = new Date(value.getTime());
    } else if (type == "RegExp") {
      result[key] = RegExp((value as RegExp).source, getRegExpFlags(value));
    } else {
      result[key] = value;
    }
  }
  return result;
}

function getRegExpFlags(regExp: RegExp) {
  if (typeof (regExp.source as any).flags == "string") {
    return (regExp.source as any).flags;
  } else {
    const flags = [];
    regExp.global && flags.push("g");
    regExp.ignoreCase && flags.push("i");
    regExp.multiline && flags.push("m");
    regExp.sticky && flags.push("y");
    regExp.unicode && flags.push("u");
    return flags.join("");
  }
}
