import {
  DateRange,
  DayEntryReportItem,
  ExtraTimeReportItem,
  ScheduleEntryClockEx,
  SyncInfo,
  TeamRole,
  TeamScheduleEntryEx,
} from "@momentum/model";
import { TeamSchedule } from "./TeamSchedule.js";
import { UserSchedule } from "./UserSchedule.js";
import { newRecord, Record, RecordStatus, resetRecord } from "../recordset.js";
import type { ApiClient } from "../ApiClient.js";

export class ScheduleService {
  constructor(public readonly client: ApiClient) {}
  async getTeamSchedule(
    IDTeam: number,
    dateInit: Date,
    dateEnd: Date,
    IDUser?: number,
    IDTeamRole: TeamRole = TeamRole.EMPLOYEE
  ) {
    const [entries, shifts, specialShiftCodes] = await Promise.all([
      this.client
        .invoke(`team/${IDTeam}/schedule`)
        .query({
          IDUser,
          IDTeamRole,
        })
        .dateRange(dateInit, dateEnd)
        .get<TeamScheduleEntryEx>(),
      this.client.shift.getShiftMap(IDTeam),
      this.client.shift.getSpecialShiftCodes(),
    ]);

    return new TeamSchedule(
      dateInit,
      dateEnd,
      entries.map(
        (e) =>
          new UserSchedule(
            dateInit,
            dateEnd,
            e.schedule.map((se) =>
              newRecord(se, undefined, RecordStatus.Unmodified)
            ),
            e.user,
            shifts,
            specialShiftCodes,
            IDTeam
          )
      ),
      shifts,
      specialShiftCodes,
      IDTeam
    );
  }

  async getUserSchedule(
    IDTeam: number,
    IDUser: number,
    dateInit: Date,
    dateEnd: Date
  ) {
    const teamSchedule = await this.getTeamSchedule(
      IDTeam,
      dateInit,
      dateEnd,
      IDUser
    );

    return (
      teamSchedule.members.get(IDUser) ||
      new UserSchedule(
        dateInit,
        dateEnd,
        [],
        { IDUser: IDUser },
        teamSchedule.shifts,
        teamSchedule.specialShiftCodes,
        IDTeam
      )
    );
  }

  async putSchedule(teamSchedule: TeamSchedule, IDUser?: number) {
    const syncInfo = teamSchedule.sync();
    const syncResult = await this.client
      .invoke(`team/${teamSchedule.IDTeam}/schedule/sync`)
      .dateRange(teamSchedule.dateInit, teamSchedule.dateEnd)
      .query({ IDUser })
      .post<
        SyncInfo<Record<ScheduleEntryClockEx>>,
        SyncInfo<ScheduleEntryClockEx>
      >(syncInfo);

    if (
      syncInfo.deleted.length != syncResult.deleted.length ||
      syncInfo.modified.length != syncResult.modified.length ||
      syncInfo.new.length != syncResult.new.length
    )
      throw "sync response length mismatch";

    syncInfo.modified.forEach((se, i) =>
      resetRecord(se, syncResult.modified[i])
    );
    syncInfo.new.forEach((se, i) => resetRecord(se, syncResult.new[i]));
    teamSchedule.updateDeleted(syncInfo.new, syncResult.deleted);
  }

  async putUserSchedule(userSchedule: UserSchedule) {
    const teamSchedule = new TeamSchedule(
      userSchedule.dateInit,
      userSchedule.dateEnd,
      [userSchedule],
      userSchedule.shifts,
      userSchedule.specialShiftCodes,
      userSchedule.IDTeam
    );
    return this.putSchedule(teamSchedule, userSchedule.user.IDUser);
  }

  async getExtraTimeReport(dateRange: DateRange) {
    return this.client
      .invoke("reports/extra-time")
      .dateRange(dateRange)
      .get<ExtraTimeReportItem>();
  }

  async getDayEntryReport(dateRange: DateRange, userIDs: number[] = []) {
    return this.client
      .invoke("reports/day-entries")
      .dateRange(dateRange)
      .filter({
        filters: [
          { OR: [{ field: "IDUser", matchMode: "in", value: userIDs }] },
        ],
      })
      .get<DayEntryReportItem>();
  }
}
