import { TeamRole, TeamMemberUser } from "@momentum/model";
import { ApiRecordset } from "./ApiRecordset.js";
import type { ApiClient } from "./ApiClient.js";

export class TeamMemberRecordset extends ApiRecordset<
  TeamMemberUser,
  "IDTeam" | "IDTeamGroup"
> {
  constructor(client: ApiClient, private IDTeam: number = 0) {
    super({
      client,
      primaryKey: "User.IDUser",
      default: () => ({
        IDTeam: this.IDTeam,
        IDTeamGroup: null,
        TeamGroup: null,
        IDTeamRole: TeamRole.EMPLOYEE,
        User: { IDUser: 0, FirstName: "Nombre", LastName: "Apellido" },
      }),
      path: {
        query: () => `team/${this.IDTeam}/member`,
        new: (item) => `team/${this.IDTeam}/member`,
        update: (item) => `team/${this.IDTeam}/member/${item.User.IDUser}`,
        remove: (item) => `team/${this.IDTeam}/member/${item.User.IDUser}`,
      },
    });
  }

  async setIDTeam(IDTeam: number) {
    this.IDTeam = IDTeam;
    return this.query();
  }
}
