import {
  reactive,
  ComputedRef,
  Ref,
  computed,
  watchEffect,
  WatchStopHandle,
  WritableComputedRef,
  ref,
  watch,
  readonly,
  WatchSource,
} from "vue";

enum AsyncStatus {
  ERROR = "error",
  SUCCESS = "success",
  LOADING = "loading",
}

type AsyncBase<X> = X & {
  status: Ref<AsyncStatus>;
  error: Ref<any>;
  retry: () => void;
};

type AsyncGetter<T> = () => Promise<T>;
type AsyncSetter<T> = (value?: T) => void;
interface AsyncComputedGetSet<T> {
  get: AsyncGetter<T>;
  set: AsyncSetter<T>;
}
interface AsyncComputedOptions<T> {
  default?: T | Ref<T>;
  lazy?: boolean;
}

export type AsyncAccess<T> = AsyncBase<ComputedRef<T>>;
export type AsyncAccessWritable<T> = AsyncBase<WritableComputedRef<T>>;

export function asyncComputed<T>(
  getset: AsyncComputedGetSet<T>,
  options?: AsyncComputedOptions<T>
): AsyncAccessWritable<T | undefined>;
export function asyncComputed<T>(
  func: AsyncGetter<T>,
  options?: AsyncComputedOptions<T>
): AsyncAccess<T | undefined>;
export function asyncComputed<T>(
  func: AsyncGetter<T> | AsyncComputedGetSet<T>,
  options: AsyncComputedOptions<T> = {}
): AsyncAccess<T | undefined> {
  const optionsWithDefaults = {
    default: undefined,
    lazy: false,
    ...options,
  };
  const state = reactive({
    result: undefined as any | undefined,
    status: AsyncStatus.LOADING,
    error: null as any,
    hasEverRun: false,
  });
  let hasEverRequested = false;

  const resultGetter = () => {
    if (!hasEverRequested) {
      retry();
    }
    if (state.hasEverRun) {
      return state.result;
    } else {
      return options.default as T | undefined;
    }
  };

  const result =
    typeof func === "function"
      ? computed<T | undefined>(resultGetter)
      : computed<T | undefined>({
          get: resultGetter,
          set: func.set,
        });

  const getter = typeof func === "function" ? func : func.get;

  let lastRetryCalled: Symbol | null = null;
  let stopLast: WatchStopHandle | null = null;

  const retry = () => {
    hasEverRequested = true;
    if (stopLast) {
      stopLast();
      stopLast = null;
    }

    stopLast = watchEffect(() => {
      const me = Symbol("retry");
      lastRetryCalled = me;
      state.status = AsyncStatus.LOADING;
      state.error = null;
      getter().then(
        (value) => {
          if (lastRetryCalled === me) {
            state.status = AsyncStatus.SUCCESS;
            state.result = value;
            state.error = null;
            state.hasEverRun = true;
          }
        },
        (error) => {
          if (lastRetryCalled === me) {
            state.status = AsyncStatus.ERROR;
            state.error = error;
            state.hasEverRun = true;
          }
        }
      );
    });
  };

  if (!optionsWithDefaults.lazy) {
    retry();
  }

  Object.defineProperty(result, "status", {
    value: computed(() => state.status),
  });
  Object.defineProperty(result, "error", {
    value: computed(() => state.error),
  });
  Object.defineProperty(result, "retry", {
    value: retry,
  });
  return result as AsyncAccess<T>;
}

export function asyncRef<T>(
  source: WatchSource<any>,
  onChange: () => Promise<T | undefined>
) {
  const r: Ref<T | undefined> = ref(undefined);
  watch(
    source,
    () => {
      onChange().then((v) => (r.value = v));
    },
    { immediate: true }
  );
  return readonly(r);
}
