import { AuthToken } from "@momentum/common";
import { SubEvent } from "sub-events";
import superagent from "superagent";
import { ApiRequest } from "./ApiRequest.js";
import { HTTP } from "./HTTP.js";
import { ClockRecordService } from "./ClockRecord.js";
import { ClockRecordSourceService } from "./ClockRecordSource.js";
import { HolidayService } from "./Holiday.js";
import { ScheduleService } from "./schedule/ScheduleService.js";
import { ScheduleTemplateService } from "./ScheduleTemplate.js";
import { ShiftService } from "./ShiftsService.js";
import { TeamService } from "./TeamService.js";
import { UserService } from "./User.js";
import { RoleService } from "./Role.js";
import { NFCService } from "./NFCService.js";

export class ApiClient {
  private _jwt: string | undefined;
  private http: HTTP;
  private _token: AuthToken | undefined;
  private tokenDuration: number = 0;
  private timer: any;
  public readonly onLogin = new SubEvent<AuthToken>();
  public readonly onNewToken = new SubEvent<AuthToken>();
  public readonly onLogout = new SubEvent();
  public readonly clockRecord: ClockRecordService;
  public readonly clockRecordSource: ClockRecordSourceService;
  public readonly holiday: HolidayService;
  public readonly scheduleTemplate: ScheduleTemplateService;
  public readonly shift: ShiftService;
  public readonly team: TeamService;
  public readonly user: UserService;
  public readonly schedule: ScheduleService;
  public readonly role: RoleService;
  public readonly nfc: NFCService;

  constructor(
    backendUrl: string,
    private agent: superagent.SuperAgentStatic & superagent.Request
  ) {
    this.http = new HTTP(this.agent, backendUrl, (res) => {
      if (res.code === 401 && this._jwt) this.logout();
    });
    this.agent.use((req) => {
      if (this._jwt) req.set("Authorization", `Bearer ${this._jwt}`);
    });
    this.clockRecord = new ClockRecordService(this);
    this.clockRecordSource = new ClockRecordSourceService(this);
    this.holiday = new HolidayService(this);
    this.scheduleTemplate = new ScheduleTemplateService(this);
    this.shift = new ShiftService(this);
    this.team = new TeamService(this);
    this.user = new UserService(this);
    this.schedule = new ScheduleService(this);
    this.role = new RoleService(this);
    this.nfc = new NFCService(this);
  }

  public invoke(path: string) {
    return new ApiRequest(path, this.http);
  }

  public async login(username: string, password: string): Promise<void> {
    const jwt = await this.invoke("login").post<any, string>({
      username,
      password,
    });
    this.setJWT(jwt);
  }

  public async logout() {
    this._token = undefined;
    this._jwt = undefined;
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
    this.onLogout.emit(null);
  }

  public setJWT(jwt: string) {
    const newToken = new AuthToken(jwt);
    if (newToken.expired) return;
    console.log("AuthToken: ", newToken);
    const wasLoggedOut = !this._token;
    this._jwt = jwt;
    this._token = newToken;
    this.tokenDuration = newToken.expires.getTime() - new Date().getTime();
    if (wasLoggedOut) this.onLogin.emit(newToken);
    this.onNewToken.emit(newToken);

    if (this.timer) clearInterval(this.timer);

    // set up a timer to periodically renew the token
    this.timer = setInterval(() => {
      if (!this._token) {
        if (!this.timer) clearInterval(this.timer);
        this.timer = undefined;
        return;
      }
      // check token validity
      if (this._token.expired) {
        this.logout();
        return;
      }

      // see if it is time to renew the token
      // we'll try to renew after half the token duration has
      // elapsed
      const now = new Date().getTime();
      if (now > this._token.issuedAt.getTime() + this.tokenDuration / 2)
        // special username "$$TOKEN$$" allows to use current jwt as a password
        // to renew the jwt
        this.login("$$TOKEN$$", this._jwt as string);
    }, Math.max(this.tokenDuration * 0.007, 5 * 1000));
  }

  public get token(): AuthToken | undefined {
    return this._token;
  }
  public get jwt(): string | undefined {
    return this._jwt;
  }
}
