<template>
  <div class="layout-topbar">
    <button class="p-link layout-menu-button" @click="onMenuToggle">
      <span class="pi pi-bars"></span>
    </button>
    <div class="layout-menu-title">{{ title }}</div>
    <div class="layout-topbar-icons">
      <!--       <span class="layout-topbar-search">
        <InputText type="text" placeholder="Search" />
        <span class="layout-topbar-search-icon pi pi-search"></span>
      </span> -->
      <!--       <button class="p-link">
        <span class="layout-topbar-icon pi pi-cog"></span>
        <span class="layout-topbar-badge">5</span>
              </button> -->

      <Button class="p-link" @click="toggle"
        ><span class="layout-topbar-icon"
          ><Chip class="chip-username"
            ><i class="pi pi-user" style="font-weight: bold"></i>&nbsp;<UserInfo
              v-if="token"
              :IDUser="token.IDUser"
              v-slot="{ user }"
              ><div class="username">
                {{ user?.LastName }}, {{ user?.FirstName }}
              </div></UserInfo
            ></Chip
          ></span
        ></Button
      ><Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
    </div>
    <ResetPasswordDialog
      v-model:visible="resetPasswordDialogVisible"
      :IDUser="resetPasswordIDUser"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { token } from "./client";
import UserInfo from "@/components/UserInfo.vue";
import ResetPasswordDialog from "@/components/ResetPasswordDialog.vue";
import { useConfirm } from "primevue/useconfirm";
import { client } from "@/client";
import { useToast } from "primevue/usetoast";

export default defineComponent({
  components: { UserInfo, ResetPasswordDialog },
  methods: {
    onMenuToggle(event: any) {
      this.$emit("menu-toggle", event);
    },
  },

  props: {
    title: String,
  },

  setup() {
    const $confirm = useConfirm();
    const $toast = useToast();
    const resetPasswordIDUser = ref(client.token?.IDUser || 0);
    const resetPasswordDialogVisible = ref(false);
    const menu = ref();
    const items = ref([
      {
        label: "Cambiar contraseña",
        icon: "pi pi-key",
        command: () => (resetPasswordDialogVisible.value = true),
      },
      {
        label: "Salir",
        icon: "pi pi-power-off",
        command: onLogout,
      },
    ]);

    client.onLogin.subscribe(() => {
      resetPasswordIDUser.value = client.token?.IDUser as number;
      if (client.token?.mustChangePassword) {
        resetPasswordDialogVisible.value = true;
        $toast.add({
          summary:
            "Su contraseña ha caducado. Por favor introduzca una nueva contraseña",
          severity: "info",
          life: 5000,
        });
      }
    });

    const toggle = (event: Event) => {
      menu.value.toggle(event);
    };

    function onLogout() {
      $confirm.require({
        message: "¿Está seguro de que desea cerrar la sesión?",
        icon: "pi pi-power-off",
        header: "Cerrar sesión",
        accept: () => {
          client.logout();
        },
      });
    }
    return {
      token,
      onLogout,
      items,
      menu,
      toggle,
      resetPasswordIDUser,
      resetPasswordDialogVisible,
    };
  },
});
</script>

<style scoped>
.chip-username {
  max-width: 10em;
  margin-left: 10px;
  vertical-align: top;
  height: 1.5em;
}

div.username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>