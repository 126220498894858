import { dateUtils } from "@momentum/common";
import {
  ScheduleEntryClockEx,
  SpecialShiftCodesMap,
  SyncInfo,
  TeamScheduleEntry,
  UserBasicInfo,
} from "@momentum/model";
import { ScheduleEntry, User } from "@prisma/client";
import { ShiftMap } from "../ShiftsService.js";
import { DayEntry } from "./DayEntry.js";
import { Record, RecordStatus } from "../recordset.js";

export class UserSchedule {
  public readonly dayEntries: Map<number, DayEntry> = new Map();
  public readonly originalSchedule: Map<number, Record<ScheduleEntryClockEx>> =
    new Map();
  constructor(
    public readonly dateInit: Date,
    public readonly dateEnd: Date,
    schedule: Record<ScheduleEntryClockEx>[],
    public readonly user: UserBasicInfo,
    public readonly shifts: ShiftMap,
    public readonly specialShiftCodes: SpecialShiftCodesMap,
    public readonly IDTeam: number
  ) {
    schedule.forEach((s) => {
      this.originalSchedule.set(s.IDScheduleEntry, s);
      this.day(dateUtils.twelveAM(s.DateInit)).add(s);
    });
  }
  public inRange(date: Date): boolean {
    return (
      date.getTime() >= this.dateInit.getTime() &&
      date.getTime() < this.dateEnd.getTime()
    );
  }
  public day(date: Date): DayEntry {
    if (!this.inRange(date)) throw "date out of range";

    let de = this.dayEntries.get(date.getTime());
    if (!de) {
      de = new DayEntry(
        date,
        [],
        this.shifts,
        this.specialShiftCodes,
        this.user.IDUser,
        this.IDTeam
      );
      this.dayEntries.set(date.getTime(), de);
      return this.dayEntries.get(date.getTime()) as DayEntry; // get again from the map so a reactive proxied version is returned
    }
    return de;
  }
  public days(): DayEntry[] {
    return dateUtils.mapDay(this.dateInit, this.dateEnd, (date) =>
      this.day(date)
    );
  }
  public dirty(): boolean {
    return [...this.dayEntries.values()].some((de) => de.dirty);
  }
  public clean() {
    for (const de of this.dayEntries.values()) {
      de.dirty = false;
    }
  }
  public entry(): TeamScheduleEntry {
    const schedule: ScheduleEntry[] = [];
    for (const de of this.dayEntries.values()) {
      schedule.push(...de.entries());
    }
    return {
      user: this.user as User,
      schedule: schedule,
    };
  }

  public get totalWorkTime() {
    return [...this.dayEntries.values()].reduce(
      (total, de) => total + de.totalWorkTime,
      0
    );
  }

  public get totalRealWorkTime() {
    return [...this.dayEntries.values()].reduce(
      (total, de) => total + de.totalRealWorkTime,
      0
    );
  }

  public get totalExtraWorkTime() {
    return [...this.dayEntries.values()].reduce(
      (total, de) => total + de.totalExtraWorkTime,
      0
    );
  }

  public countCode(code: string) {
    return [...this.dayEntries.values()].reduce(
      (total, s) => total + ((s.code === code && 1) || 0),
      0
    );
  }

  public sync(): SyncInfo<Record<ScheduleEntryClockEx>> {
    const si: SyncInfo<Record<ScheduleEntryClockEx>> = {
      new: [],
      modified: [],
      deleted: [],
    };
    const deleted = new Map(this.originalSchedule);

    for (const de of this.dayEntries.values()) {
      de.entries().forEach((se) => {
        if (se.__status === undefined || se.__status === RecordStatus.New) {
          si.new.push(se);
        } else {
          if (se.__status === RecordStatus.Dirty) {
            si.modified.push(se);
          }
          deleted.delete(se.IDScheduleEntry);
        }
      });
    }
    si.deleted = [...deleted.keys()];
    return si;
  }

  public updateDeleted(deleted: number[]) {
    deleted.forEach((i) => this.originalSchedule.delete(i));
  }

  public updateNew(newRecord: Record<ScheduleEntryClockEx>) {
    this.originalSchedule.set(newRecord.IDScheduleEntry, newRecord);
  }
}
