import { ApiRecordset } from "./ApiRecordset.js";
import type { ApiClient } from "./ApiClient.js";
import { TeamCategory } from "@prisma/client";

export class TeamCategoryRecordset extends ApiRecordset<
  TeamCategory,
  "IDTeamCategory"
> {
  constructor(client: ApiClient) {
    super({
      client,
      primaryKey: "IDTeamCategory",
      default: () => ({
        TeamCategoryName: "",
      }),
      path: {
        query: () => `team/category`,
        new: (item) => `team/category`,
        update: (item) => `team/category/${item.IDTeamCategory}`,
        remove: (item) => `team/category/${item.IDTeamCategory}`,
      },
    });
  }
}
