import { TeamGroup } from "@momentum/model";
import { ApiRecordset } from "./ApiRecordset.js";
import type { ApiClient } from "./ApiClient.js";

export class TeamGroupRecordset extends ApiRecordset<TeamGroup, "IDTeamGroup"> {
  constructor(client: ApiClient, private IDTeam: number = 0) {
    super({
      client,
      default: () => ({
        IDTeamGroup: 0,
        IDTeam: this.IDTeam,
        DateInit: new Date(),
        TeamGroupName: "",
        WeekNames: [],
      }),
      path: {
        query: () => `team/${this.IDTeam}/group`,
        new: (item) => `team/${this.IDTeam}/group`,
        update: (item) => `team/${this.IDTeam}/group/${item.IDTeamGroup}`,
        remove: (item) => `team/${this.IDTeam}/group/${item.IDTeamGroup}`,
      },
      primaryKey: "IDTeamGroup",
    });
  }

  async setIDTeam(IDTeam: number) {
    this.IDTeam = IDTeam;
    this.query();
  }
}
