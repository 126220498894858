import jwt from "jwt-decode";
import { clone } from "./jsUtils.js";
import { Permission, TeamRole, Plain } from "@momentum/model";

export type PlainAuthToken = Plain<
  AuthToken & { permissions?: (Permission | string)[] }
>;

const jwtDecode = jwt as any as (token: string) => any;

export type TeamIDInfo = [number, TeamRole]; //[IDTeam, IDTeamRole]

export class AuthToken {
  public IDUser: number = 0;
  public teams: TeamIDInfo[] = [];
  public access: number[] = [];
  public iat?: number;
  public exp?: number;

  constructor();
  constructor(obj: Plain<AuthToken>);
  constructor(token: string);
  constructor(tokenOrObject: string | PlainAuthToken = {}) {
    try {
      if (typeof tokenOrObject === "string") {
        tokenOrObject = jwtDecode(tokenOrObject) as PlainAuthToken;
      }

      if (tokenOrObject.permissions) {
        this.set(...tokenOrObject.permissions);
        tokenOrObject = { ...tokenOrObject };
        delete tokenOrObject.permissions;
      }

      for (let keys = Object.keys(tokenOrObject), i = 0; i < keys.length; ++i)
        (this as any)[keys[i]] = (tokenOrObject as any)[keys[i]] as any;
    } catch (e: any) {
      console.log("Invalid token format", e);
    }
  }

  private pidIndex(p: string | Permission): { mask: number; index: number } {
    if (typeof p === "string") {
      p = Permission[p as any] as any as Permission;
      if (p === undefined) return { mask: -1, index: -1 };
    }
    const index = p >> 5;
    return { mask: 0x01 << (p - (index << 5)), index };
  }

  public has(...perms: Permission[]): boolean {
    return perms.every((p) => {
      if (p === undefined) {
        return false;
      }
      const { mask, index } = this.pidIndex(p);
      if (index === -1) {
        return false;
      }
      if (this.access.length < index) return false;
      return ((this.access[index] || 0) & mask) !== 0;
    });
  }

  public set(...perms: (string | Permission)[]) {
    perms.forEach((p) => {
      const { mask, index } = this.pidIndex(p);
      if (index === -1) {
        return;
      }
      this.access[index] = (this.access[index] || 0) | mask;
    });
  }

  public unset(...perms: (string | Permission)[]) {
    perms.forEach((p) => {
      const { mask, index } = this.pidIndex(p);
      if (index === -1) {
        return;
      }
      this.access[index] = (this.access[index] || 0) & ~mask;
    });
  }

  public clear() {
    this.access = [];
  }

  public toPlain(): Plain<Omit<AuthToken, "iat" | "exp">> {
    const cloned = clone(this) as any;
    delete cloned["iat"];
    delete cloned["exp"];
    return cloned;
  }

  public get issuedAt(): Date {
    return new Date((this.iat || 0) * 1000);
  }

  public get expires(): Date {
    return new Date((this.exp || 0) * 1000);
  }

  public get expired(): boolean {
    return !this.exp || new Date().getTime() > this.exp * 1000;
  }

  public get teamIDs() {
    return (
      this.teams
        //      .filter(([teamID, teamRole]) => teamRole >= 0) this would filter out TeamRole.PEER out, for now let's consider as if peers belonged to the team
        .map(([teamID, teamRole]) => teamID)
    );
  }

  public get teamInfo() {
    return this.teams.map(([IDTeam, role]) => ({ IDTeam, role }));
  }

  public belongsToTeam(IDTeam: number): boolean {
    return this.teamIDs.some((id) => IDTeam == id);
  }

  public teamRole(IDTeam: number): TeamRole | undefined {
    const t = this.teams.find(([IDTeam_, role]) => IDTeam_ == IDTeam);
    return (t && t[1]) || undefined;
  }

  public setTeamRole(IDTeam: number, role: TeamRole) {
    let t = this.teams.find(([IDTeam_, role]) => IDTeam_ == IDTeam);
    if (!t) {
      t = [IDTeam, role];
      this.teams.push(t);
    } else t[1] = role;
  }

  public get mustChangePassword(): boolean {
    return this.has(Permission.MUST_CHANGE_PASSWORD);
  }
}
