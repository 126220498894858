import { Optional } from "@momentum/model";
import { Record, Recordset, RecordsetConfig } from "./recordset.js";
import { ApiRequest } from "./ApiRequest.js";
import type { ApiClient } from "./ApiClient.js";

type RequestHook = (request: ApiRequest) => void;

export interface PathMapping<IN extends Object, PK extends keyof IN> {
  query: () => string;
  new: (item: Record<Optional<IN, PK>>) => string;
  update: (item: Record<IN>) => string;
  remove: (item: Record<IN>) => string;
}

export function FromBasePath<IN extends Object, PK extends keyof IN>(
  basePath: string
): PathMapping<IN, PK> {
  return {
    query: () => basePath,
    new: (item: Record<Optional<IN, PK>>) => basePath,
    update: (item: Record<IN>) => `${basePath}/${item.__id}`,
    remove: (item: Record<IN>) => `${basePath}/${item.__id}`,
  };
}

interface ApiRecordsetConfig<
  IN extends Object,
  PK extends keyof IN,
  OUT extends Object
> extends RecordsetConfig<IN, PK> {
  path: string | PathMapping<IN, PK>;
  client: ApiClient;
  pack?: (item: Record<Optional<IN, PK>>) => OUT;
  unpack?: (apiItem: any) => IN;
  queryHook?: RequestHook;
  newHook?: RequestHook;
  updateHook?: RequestHook;
  removeHook?: RequestHook;
}

function request(
  path: string,
  client: ApiClient,
  hook?: RequestHook
): ApiRequest {
  const request = client.invoke(path);
  hook && hook(request);
  return request;
}

export function removeProperty<T, P extends keyof T>(
  obj: T,
  prop: P
): Omit<T, P> {
  const obj2: any = Object.assign({}, obj);
  delete obj2[prop];
  return obj2;
}

export function removeProperties<T, P extends Array<keyof T>>(
  obj: T,
  ...keys: P
): Omit<T, (typeof keys)[number]> {
  const newObj: any = {};

  Object.keys(obj as any).forEach((k) => {
    if (keys.indexOf(k as any) === -1) {
      newObj[k] = (obj as any)[k];
    }
  });

  return newObj;
}
export class ApiRecordset<
  IN extends Object,
  PK extends keyof IN,
  OUT extends Object = IN
> extends Recordset<IN, PK> {
  constructor(config: ApiRecordsetConfig<IN, PK, OUT>) {
    const pack = config.pack
      ? config.pack
      : (t: Record<Optional<IN, PK>>) => t as any as OUT;
    const unpack = config.unpack
      ? (...as: any[]) => as.map((a) => (config.unpack as any)(a)) as IN[]
      : (...a: any[]) => a as any as IN[];

    const pathMapping =
      typeof config.path === "string" ? FromBasePath(config.path) : config.path;

    config.query =
      config.query ||
      async function (filter) {
        const result = await request(
          pathMapping.query(),
          config.client,
          config.queryHook
        )
          .filter(filter)
          .getPaged<IN>();
        return { items: unpack(...result.data), total: result.total };
      };

    config.new =
      config.new ||
      async function (item) {
        const newItem = await request(
          pathMapping.new(item),
          config.client,
          config.newHook
        ).post(pack(item));
        return unpack(newItem)[0];
      };
    config.update =
      config.update ||
      async function (item) {
        const updatedItem = await request(
          pathMapping.update(item),
          config.client,
          config.updateHook
        ).put(pack(item));
        return unpack(updatedItem)[0];
      };
    config.remove = async function (item) {
      await request(
        pathMapping.remove(item),
        config.client,
        config.removeHook
      ).delete();
    };
    super(config);
  }
}
