import { computed, toRefs } from "vue";
import type { WritableComputedRef } from "vue";
/*    const selected = computed({
      get: () => modelValue?.value,
      set(value) {
        emit("update:modelValue", value);
      },
    });
*/

export function pass<T extends { [propName: string]: any }>(
  props: T,
  emit: Function
): { [k in keyof T]: WritableComputedRef<T[k]> } {
  const refs = toRefs(props);
  const c: { [propName: string]: WritableComputedRef<any> } = {};

  Object.keys(refs).forEach(
    (p) =>
      (c[p] = computed({
        get: () => (refs[p] && refs[p].value) || undefined,
        set(value) {
          emit(`update:${p}`, value);
        },
      }))
  );
  return c as any;
}

export class SetupHelper<P extends object> {
  constructor(
    private props: P,
    private emit: (event: string, ...args: any[]) => void
  ) {}
  public pass(propName: keyof P) {
    const updateEvent = `update:${String(propName)}`;
    const rf = toRefs(this.props)[propName];

    return computed({
      get: () => rf.value,
      set: (v: P[typeof propName]) => this.emit(updateEvent, v),
    });
  }
}
