export interface Locale {
  dayNames: string[];
  dayNamesShort: string[];
  dayNamesMin: string[];
  firstDayOfWeek: number;
  monthNames: string[];
}

const YYYmmddRegex = /^(\d\d\d\d)-(\d\d)-(\d\d)$/; // don't put the g, regex are stateful: https://medium.com/codesnips/js-careful-when-reusing-regex-636b92c6bf07

const utils = function (
  locale: Locale,
  firstDayOfWeek: number = locale.firstDayOfWeek
) {
  return {
    twelveAM: function (datetime: Date): Date {
      const dt = new Date(datetime);
      dt.setHours(0);
      dt.setMinutes(0);
      dt.setSeconds(0);
      dt.setMilliseconds(0);
      return dt;
    },
    today: function () {
      return this.twelveAM(new Date());
    },
    addDays: function (datetime: Date, days: number): Date {
      const dt = new Date(datetime);
      dt.setDate(dt.getDate() + days);
      return dt;
    },

    jsweekday(i: number): number {
      return (i + firstDayOfWeek) % 7;
    },
    getDay(date: Date): number {
      return (date.getDay() - firstDayOfWeek + 7) % 7;
    },

    weekdayNameMin(d: Date | number): string {
      return d instanceof Date
        ? locale.dayNamesMin[d.getDay()]
        : locale.dayNamesMin[this.jsweekday(d)];
    },
    weekdayName(d: Date | number): string {
      return d instanceof Date
        ? locale.dayNames[d.getDay()]
        : locale.dayNames[this.jsweekday(d)];
    },
    forEachWeekday(f: (name: string, d: number) => void) {
      for (let d = 0; d < 7; d++) {
        f(this.weekdayName(d), d);
      }
    },
    mapWeekday<T>(f: (name: string, d: number) => T): T[] {
      const data: T[] = [];
      this.forEachWeekday((name, d) => data.push(f(name, d)));
      return data;
    },

    monthName: (d: Date) => locale.monthNames[d.getMonth()],

    weekDiff(dateInit: Date, dateEnd: Date) {
      const firstDayofFirstWeek = this.getFirstDayOfWeek(dateInit);
      const firstDayOfThisWeek = this.getFirstDayOfWeek(dateEnd);
      return Math.floor(
        this.dateDiff(firstDayofFirstWeek, firstDayOfThisWeek) / 7
      );
    },

    getWeekNumber(d: Date) {
      // Create a copy of this date object
      const target = new Date(d.valueOf());

      // ISO week date weeks start on monday
      // so correct the day number
      const dayNr = (d.getDay() + 6) % 7;

      // Set the target to the thursday of this week so the
      // target date is in the right year
      target.setDate(target.getDate() - dayNr + 3);

      // ISO 8601 states that week 1 is the week
      // with january 4th in it
      const jan4 = new Date(target.getFullYear(), 0, 4);

      // Number of days between target date and january 4th
      const dayDiff = (target.getTime() - jan4.getTime()) / 86400000;

      // Calculate week number: Week 1 (january 4th) plus the
      // number of weeks between target date and january 4th
      return 1 + Math.floor(Math.abs(dayDiff) / 7);
    },
    treatAsUTC(date: Date): Date {
      const result = new Date(date);
      result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
      return result;
    },

    dateDiff(dateInit: Date, dateEnd: Date): number {
      return (
        (this.treatAsUTC(dateEnd).getTime() -
          this.treatAsUTC(dateInit).getTime()) /
        86400000
      );
    },

    forEachDay(dateInit: Date, dateEnd: Date, f: (dt: Date) => void) {
      const length = this.dateDiff(dateInit, dateEnd);
      for (let dt = dateInit; dt < dateEnd; dt = this.addDays(dt, 1)) {
        f(dt);
      }
    },
    mapDay<T>(dateInit: Date, dateEnd: Date, f: (dt: Date) => T) {
      const data: T[] = [];
      this.forEachDay(dateInit, dateEnd, (date) => data.push(f(date)));
      return data;
    },
    getFirstDayOfWeek(date: Date): Date {
      date = this.twelveAM(date);
      return this.addDays(date, -this.getDay(date));
    },
    getLastDayOfWeek(date: Date): Date {
      date = this.twelveAM(date);
      return this.addDays(date, 6 - this.getDay(date));
    },
    toYYYYmmdd(d: Date) {
      let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    fromYYYYmmdd(st: string): Date | undefined {
      const m = YYYmmddRegex.exec(st.trim());
      return (
        (m &&
          new Date(
            parseInt(m[1]),
            parseInt(m[2]) - 1,
            parseInt(m[3]),
            0,
            0,
            0,
            0
          )) ||
        undefined
      );
    },
    isWeekend(dt: Date) {
      const weekday = dt.getDay();
      return weekday == 0 || weekday == 6;
    },
  };
};
export default utils;
