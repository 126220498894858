import { Utils } from "@momentum/common";
import {
  ScheduleEntryClockEx,
  ScheduleEntryEx,
  Shift,
  SpecialShiftCodesMap,
} from "@momentum/model";
import { SpecialShiftCode } from "@prisma/client";
import {
  copyRecord,
  isRecord,
  newRecord,
  Record,
  RecordStatus,
  resetRecord,
} from "../recordset.js";
import { ShiftMap } from "../ShiftsService.js";

export const ErrNoZeroShiftCode = new Error();
export class DayEntry {
  public readonly schedule: Record<ScheduleEntryClockEx>[];
  private shiftMap: ShiftMap;
  public readonly IDUser: number;
  private _dirty: boolean = false;
  private scheduleBackup: Record<ScheduleEntryClockEx>[] | undefined =
    undefined;
  private scheduleBackupDirty: boolean = false;

  constructor(
    public readonly date: Date,
    schedule: Record<ScheduleEntryClockEx>[],
    shiftMap: ShiftMap,
    private readonly specialShiftCodes: SpecialShiftCodesMap,
    IDUser: number,
    public readonly IDTeam: number
  ) {
    this.schedule = schedule;
    this.shiftMap = shiftMap;
    this.date = date;
    this.IDUser = IDUser;
  }

  public codes(): string[] {
    return this.shiftMap.codes();
  }

  public getShifts(): Shift[] {
    return this.shiftMap.shifts();
  }

  public get dirty(): boolean {
    return (
      this._dirty ||
      this.schedule.some((se) => se.__status !== RecordStatus.Unmodified)
    );
  }
  public set dirty(value: boolean) {
    //TODO: remove this
    this._dirty = value;
  }

  public applyShift(shiftId: number | null): void;
  public applyShift(shift: Shift): void;
  public applyShift(shiftCode: string): void;
  public applyShift(sc: string | null): void;
  public applyShift(sc: Shift | string | number | null) {
    let special: SpecialShiftCode | undefined;
    if (typeof sc === "string" && (special = this.specialShiftCodes.get(sc))) {
      if (special.Zero) {
        // if true, clean up the DayEntry to zero work
        this.schedule.length = 0;
        this.add({
          AttendanceTime: 0,
          BreakTime: 0,
          Code: sc,
          IDTeam: null,
          DateInit: this.date,
        });
      } else {
        const oldSpecial = this.specialShiftCodes.get(this.code);
        if ((oldSpecial && oldSpecial.Zero) || this.schedule.length === 0) {
          throw ErrNoZeroShiftCode; // cannot apply a nonzero code over a zero code
          // it is only possible to apply this kind of code over a real existing shift
        }
        const code = special.Code;
        this.schedule.forEach((se) => (se.Code = code));
      }
      this._dirty = true;
      return;
    }
    this.schedule.length = 0;
    this._dirty = true;
    if (sc === "") {
      return;
    }

    const shift =
      (typeof sc === "string" && this.shiftMap.fromCode(sc)) ||
      (typeof sc === "number" && this.shiftMap.fromID(sc)) ||
      (typeof sc === "object" && (sc as Shift)) ||
      null;

    if (!shift) {
      return;
    }
    shift.Intervals.forEach((i) => {
      const dt = new Date(this.date.getTime() + i.Start * 1000);
      this.add({
        AttendanceTime: i.AttendanceTime,
        BreakTime: i.BreakTime,
        Code: shift.Code,
        IDTeam: shift.IDTeam,
        DateInit: dt,
      });
    });
  }

  public get code(): string {
    return (this.schedule.length > 0 && this.schedule[0].Code) || " ";
  }
  public set code(code: string) {
    this.applyShift(code);
  }
  public get comment(): string | undefined {
    return (this.schedule.length > 0 && this.schedule[0].Comment) || undefined;
  }
  public set comment(value: string | null | undefined) {
    if (value === "" || value === undefined) value = null;
    if (this.schedule.length > 0) {
      this.schedule[0].Comment = value;
      this._dirty = true;
    }
  }
  public get hasClockRecords(): boolean {
    return this.schedule.some((s) => s.ClockIn || s.ClockOut);
  }
  public get totalWorkTime(): number {
    return this.schedule.reduce(
      (total, s) => total + s.AttendanceTime - s.BreakTime,
      0
    );
  }
  public get totalRealWorkTime(): number {
    return this.schedule.reduce(
      (total, s) =>
        total +
        (ScheduleEntryClockEx.realWorkTime(s, this.specialShiftCodes) || 0),
      0
    );
  }

  public get totalExtraWorkTime(): number {
    return this.schedule.reduce(
      (total, s) =>
        total +
        (ScheduleEntryClockEx.extraTime(s, this.specialShiftCodes) || 0),
      0
    );
  }

  public add(
    entry: Record<ScheduleEntryClockEx> | Partial<ScheduleEntryClockEx>
  ) {
    if (isRecord<ScheduleEntryEx>(entry)) {
      this.schedule.push(entry);
      return;
    }
    const record = newRecord(
      Utils.fillDefaults(entry, {
        ...ScheduleEntryClockEx.default(),
        DateInit: this.date,
        IDTeam: this.IDTeam,
        IDUser: this.IDUser,
      })
    ) as Record<ScheduleEntryClockEx>;

    this.schedule.push(record);
  }

  public remove(entry: Record<ScheduleEntryClockEx>) {
    const index = this.schedule.indexOf(entry);
    if (index !== -1) {
      this.schedule.splice(index, 1);
      this._dirty = true;
    }
  }

  public entries() {
    return this.schedule;
  }

  public attainment() {
    const sum = this.schedule.reduce(
      (sum, se) => {
        const rwt = ScheduleEntryClockEx.realWorkTime(
          se,
          this.specialShiftCodes
        );
        return {
          planned: sum.planned + ScheduleEntryClockEx.plannedWorkTime(se),
          real:
            sum.real === undefined || rwt === undefined
              ? undefined
              : sum.real + rwt,
          DateInit: se.DateInit < sum.DateInit ? se.DateInit : sum.DateInit,
          DateEnd:
            se.DateInit.getTime() / 1000 + se.AttendanceTime >
            sum.DateEnd.getTime() / 1000
              ? new Date(se.DateInit.getTime() + se.AttendanceTime * 1000)
              : sum.DateEnd,
        };
      },
      {
        planned: 0,
        real: 0 as number | undefined,
        DateInit: new Date(3000, 1, 1, 1),
        DateEnd: new Date(0),
      }
    );
    return {
      ...sum,
      attainment: sum.planned ? (sum.real || 0) / sum.planned : 0,
    };
  }

  public backup() {
    this.scheduleBackup = this.schedule.map(copyRecord);
    this.scheduleBackupDirty = this.dirty;
  }

  public restore() {
    this.schedule.length = 0;
    this._dirty = this.scheduleBackupDirty;
    if (!this.scheduleBackup) return;
    this.scheduleBackup.forEach((sb) => {
      this.schedule.push(sb);
    });
  }

  public get bookedOtherTeam() {
    return false; // deprecated
  }
}
