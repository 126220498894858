import { useToast } from "primevue/usetoast";

export default function() {
  const toast = useToast();
  const toasty = {
    error(summary: string, ...log: string[]) {
      this.errorWithDetail(summary, "", ...log);
    },
    errorWithDetail(summary: string, detail: string, ...log: string[]) {
      toast.add({
        severity: "error",
        summary: summary,
        detail: detail,
        life: 3000,
      });
      console.error(summary + `:\n`, ...log);
    },
    success(summary: string, detail?: string) {
      toast.add({
        severity: "success",
        summary: summary,
        detail: detail,
        life: 3000,
      });
    },
  };
  return { ...toast, ...toasty };
}
