import { ApiPagedResponse, dateUtils, FilterQuery } from "@momentum/common";
import { DateRange } from "@momentum/model";
import { HTTP } from "./HTTP.js";

export type NewApiRequest = (path: string) => ApiRequest;
export class ApiRequest {
  private params: any = {};
  constructor(private path: string, private http: HTTP) {}

  dateRange(dateRange: DateRange): ApiRequest;
  dateRange(DateInit: Date, DateEnd?: Date): ApiRequest;
  dateRange(dateInitOrDateRange: Date | DateRange, dateEnd?: Date): ApiRequest {
    if (dateInitOrDateRange instanceof Array) {
      [dateInitOrDateRange, dateEnd] = dateInitOrDateRange;
    }
    dateEnd = dateEnd || dateUtils.addDays(dateInitOrDateRange, 1);
    this.params.DateInit = dateInitOrDateRange.toISOString();
    this.params.DateEnd = dateEnd.toISOString();
    return this;
  }

  filter(filter?: FilterQuery): ApiRequest {
    if (filter) this.params.p = JSON.stringify(filter);
    return this;
  }

  query(params: any): ApiRequest {
    if (params) {
      Object.assign(this.params, params);
    }
    return this;
  }

  async getSingle<T>(): Promise<T> {
    const response = await this.http.get<T>(this.path, this.params);
    if (response.type !== "single") {
      const err = `Expected single object calling GET ${this.path}, received '${response.type}'`;
      console.log(err);
      throw err;
    }
    return response.data;
  }
  async get<T>(): Promise<T[]> {
    const response = await this.http.get<T>(this.path, this.params);
    if (response.type !== "multiple") {
      const err = `Expected multiple objects calling GET ${this.path}, received '${response.type}'`;
      console.log(err);
      throw err;
    }
    return response.data;
  }
  async getPaged<T>(): Promise<ApiPagedResponse<T>> {
    const response = await this.http.get<T>(this.path, this.params);
    if (response.type !== "paged") {
      const err = `Expected a paged response calling GET ${this.path}, received '${response.type}'`;
      console.log(err);
      throw err;
    }
    return response;
  }
  async post<T, R = T>(item: T): Promise<R> {
    const response = await this.http.post<T, R>(this.path, item);
    if (response.type !== "single") {
      const err = `Expected a 'single' response calling POST(new) ${this.path}, received '${response.type}'`;
      console.log(err);
      throw err;
    }
    return response.data;
  }

  async postCommand<T, R = T>(item: T): Promise<void> {
    const response = await this.http.post<T, R>(this.path, item);
    if (response.type !== "ok") {
      const err = `Expected an 'ok' response calling POST ${this.path}, received '${response.type}'`;
      console.log(err);
      throw err;
    }
  }

  async put<T>(item: T): Promise<T> {
    const response = await this.http.put<T>(this.path, item);
    if (response.type !== "single") {
      const err = `Expected an 'single' response calling PUT ${this.path}, received '${response.type}'`;
      console.log(err);
      throw err;
    }
    return response.data;
  }
  async delete() {
    const response = await this.http.delete(this.path);
    if (response.type !== "ok") {
      const err = `Expected an 'ok' response calling DELETE ${this.path}, received '${response.type}'`;
      console.log(err);
      throw err;
    }
  }
}
