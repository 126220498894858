<template>
  <div class="layout-footer">
    <span class="footer-text" style="margin-right: 5px">FNSC</span>
    <img src="/assets/layout/images/logo.svg" alt="momentum" width="80" />
    {{ buildInfo.commit }}, built on
    {{ dateTimeFormatter.format(buildInfo.date) }}
  </div>
</template>

<script >
import { dateTimeFormatter } from "@momentum/common";

export default {
  name: "AppFooter",
  data: () => ({
    buildInfo: window.buildInfo,
    dateTimeFormatter,
  }),
};
</script>

<style scoped>
</style>