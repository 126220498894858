<template>
  <Dialog header="Restablecer contraseña" v-model:visible="dialogVisible">
    <div class="field">
      <div class="p-float-label">
        <Password
          toggleMask
          id="password"
          v-model="password"
          autocomplete="new-password"
          :class="{ 'p-invalid': !validatePassword() }"
        />
        <label for="password">Contraseña</label>
      </div>
    </div>
    <div class="field">
      <div class="p-float-label">
        <Password
          toggleMask
          id="repeatPassword"
          autocomplete="new-password"
          v-model="repeatPassword"
          :class="{ 'p-invalid': !validatePassword() }"
        />
        <label for="repeatPassword">Repetir Contraseña</label>
      </div>
    </div>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="dialogVisible = false"
      />
      <Button
        label="Aceptar"
        icon="pi pi-check"
        class="p-button-text"
        autofocus
        @click="resetPassword"
        :disabled="!validatePassword()"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { pass } from "@/utilities/SetupHelper";
import { defineComponent, ref, toRefs, watch } from "vue";
import useToast from "@/utilities/toasty";
import { client } from "@/client";

export default defineComponent({
  props: {
    IDUser: { type: Number, required: true },
    visible: { type: Boolean, required: true },
  },
  setup(props, { emit }) {
    const { visible } = pass(props, emit);
    const { IDUser } = toRefs(props);
    const $toast = useToast();
    const password = ref("");
    const repeatPassword = ref("");

    watch(visible, (newV) => {
      if (newV) {
        repeatPassword.value = password.value = "";
      }
    });

    function validatePassword() {
      return (
        repeatPassword.value == password.value && password.value.length > 0
      );
    }

    async function resetPassword() {
      visible.value = false;
      try {
        await client.user.resetPassword(IDUser.value, password.value);
        $toast.success("Contraseña establecida correctamente");
        if (client.token?.mustChangePassword) {
          $toast.add({
            summary: "Utilice la nueva contraseña para acceder a la aplicación",
            severity: "info",
            life: 3000,
          });
          setTimeout(() => client.logout(), 3000);
        }
      } catch (e: any) {
        $toast.errorWithDetail("Error cambiando contraseña", e);
      }
    }

    return {
      dialogVisible: visible,
      password,
      repeatPassword,
      validatePassword,
      resetPassword,
    };
  },
});
</script>

<style scoped></style>
