import { createRouter, createWebHashHistory } from "vue-router";
import type { RouteRecordRaw } from "vue-router";

import menuDefinition, { type RouteRecordExt } from "./menu-definition";
import type { MenuItem } from "./menu-definition";

import { token } from "./client";
import { Permission } from "@momentum/model";

// define here routes without a direct menu item association
export const routes: RouteRecordExt[] = [
  {
    path: "/scheduleManager/year/:date?/team/:IDTeam?/user/:IDUser?",
    name: "scheduleManager-yearly",
    label: "Anual",
    component: () => import("./pages/schedule/yearly/Main.vue"),
    permissions: [],
  },
  {
    path: "/scheduleManager/year/:year?/month/:month?/team/:IDTeam?/user/:IDUser?",
    name: "scheduleManager-monthly",
    label: "Mensual",
    component: () => import("./pages/schedule/monthly/Main.vue"),
    permissions: [
      Permission.ADMIN,
      Permission.READ_ALL_SCHEDULES,
      Permission.WRITE_TEAM_SCHEDULE,
    ],
  },
  {
    path: "/scheduleManager/team/:teamIDs?/date/:date?/days/:days?",
    label: "Cuadrantes",
    name: "scheduleManager",
    component: () => import("./pages/schedule/Main.vue"),
    permissions: [
      Permission.ADMIN,
      Permission.READ_ALL_SCHEDULES,
      Permission.WRITE_TEAM_SCHEDULE,
    ],
  },
  {
    path: "/scheduleManager/my-schedule/team/:teamIDs?/date/:date?",
    name: "my-schedule",
    label: "Mi Horario",
    component: () => import("./pages/schedule/my-schedule/Main.vue"),
    permissions: [],
  },
  {
    path: "/clock-in",
    name: "clock-in",
    label: "Registro NFC",
    component: () => import("./pages/clock-in/Main.vue"),
    props: (route) => ({ ...route.query }),
    permissions: [],
  },
  {
    path: "/reports/extra-time/date-init/:dateInit?/date-end/:dateEnd?",
    name: "extra-time",
    label: "Actividad Extra",
    component: () => import("./pages/reports/ExtraTime.vue"),
    permissions: [],
  },
  {
    path: "/reports/day-entries/users/:userIDs?/date-init/:dateInit?/date-end/:dateEnd?",
    name: "day-entries",
    label: "Diario",
    component: () => import("./pages/reports/DayEntries.vue"),
    permissions: [],
  },
  {
    label: "Route Refs demo",
    path: "/localdemo/RouteRefs-demo/a/:a?/b/:b?/c/:c?/d/:d?",
    name: "RouteRefs-demo",
    component: () => import("./components/demo/RouteRefsDemo.vue"),
    permissions: [Permission.ADMIN],
  },
];

routes.forEach((route) => {
  route.beforeEnter = (to, from, next) => {
    if (
      !route.permissions ||
      route.permissions.length === 0 ||
      route.permissions.some((perm) => token.value?.has(perm))
    ) {
      next();
    } else {
      console.log(
        "route cannot go there. Need any of " +
          Permission.toString(route.permissions)
      );
      next(false);
    }
  };
});

function generateRoutes(menu: MenuItem[], parentVisible: () => boolean) {
  menu.forEach((item) => {
    item.visible = parentVisible;
    if (item.permissions) {
      const perms = item.permissions;
      item.visible = () =>
        parentVisible() && perms.some((perm) => token.value?.has(perm));
    }
    if (item.route) {
      if (!item.route.path) {
        if (!item.to) {
          throw `Menu item '${item.label}' does not have a properly configured route path`;
        }
        item.route.path = item.to;
      }
      if (!item.route.name) {
        if (!item.to) {
          throw `Menu item '${item.label}' does not have a properly configured route name`;
        }
        item.route.name = item.to;
      }
      item.route.beforeEnter = (to, from, next) => {
        if (
          typeof item.visible === "function"
            ? item.visible()
            : item.visible || false
        ) {
          next();
        } else {
          console.log("cannot go there");
          next(false);
        }
      };
      routes.push(item.route as RouteRecordExt);
    }

    if (item.items) generateRoutes(item.items, item.visible);
  });
}

generateRoutes(menuDefinition, () => true);

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
