import { ApiResponse } from "@momentum/common";
import superagent from "superagent";

type ApiResponseWithCode<R> = ApiResponse<R> & { code: number };

export class HTTP {
  constructor(
    private agent: superagent.SuperAgentStatic,
    private backendUrl: string,
    private onError: (res: ApiResponseWithCode<any>) => void
  ) {}

  async tryRequest<R>(
    method: string,
    path: string,
    f: (path: string) => Promise<ApiResponseWithCode<R>>
  ) {
    try {
      const response = await f(`${this.backendUrl}/${path}`);
      if (response.type === "error") {
        this.onError(response);
        throw { response, type: "error" };
      }
      if (response.code < 200 || response.code > 299) {
        throw { response, message: `HTTP Error ${response.code}` };
      }
      return response;
    } catch (errx) {
      const error = errx as any; // IDE formatter removes :any from above...
      let message = `API error calling ${method} ${path}`;

      let jsonErrorMessage = "";
      let jsonError: any = {};
      if (error.response.text) {
        try {
          jsonError = JSON.parse(error.response.text);
          jsonErrorMessage = jsonError.message;
        } catch {}
      }
      if (jsonErrorMessage) {
        message = message + ": " + jsonErrorMessage;
      } else {
        if (error.response?.data?.message) {
          message = message + ": " + error.response.data.message;
        } else if (error.request) {
          message + ": no response received";
        } else if (error.message) {
          message = message + ": " + error.message;
        } else if (error?.response?.message) {
          message = message + ": " + JSON.stringify(error.response.message);
        } else {
          message = message + ": " + JSON.stringify(error.response);
        }
      }
      throw { message, inner: jsonError };
    }
  }

  get<T>(path: string, params?: any | undefined) {
    return this.tryRequest<T>("GET", path, (path) => {
      return this.agent
        .get(path)
        .query(params)
        .then((res) => ({ ...res.body, code: res.status }));
    });
  }

  post<T, R = T>(path: string, data: T) {
    return this.tryRequest<R>("POST", path, (path) => {
      return this.agent
        .post(path)
        .send(data as any)
        .then((res) => ({ ...res.body, code: res.status }));
    });
  }

  put<T, R = T>(path: string, data: T) {
    return this.tryRequest<R>("PUT", path, (path) => {
      return this.agent
        .put(path)
        .send(data as any)
        .then((res) => ({ ...res.body, code: res.status }));
    });
  }

  async delete(path: string) {
    return this.tryRequest("DELETE", path, (path) => {
      return this.agent
        .delete(path)
        .then((res) => ({ ...res.body, code: res.status }));
    });
  }
}
