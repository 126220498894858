<template>
  <Dialog header="Login" :visible="!token" :modal="true">
    <div class="field">
      <div class="p-float-label">
        <InputText id="username" v-model="username" />
        <label for="username">Usuario</label>
      </div>
    </div>
    <div class="field">
      <div class="p-float-label">
        <Password
          id="password"
          v-model="password"
          :feedback="false"
          v-on:keyup.enter="onLogin"
        />
        <label for="password">Contraseña</label>
      </div>
    </div>
    <template #footer>
      <Button
        label="Iniciar sesión"
        icon="pi pi-check"
        class="p-button-text"
        :disabled="!username || !password"
        autofocus
        @click="onLogin"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { token } from "@/client";
import useToast from "@/utilities/toasty";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { client } from "@/client";

export default defineComponent({
  setup() {
    const password = ref("");
    const username = ref("");

    const router = useRouter();

    const $toast = useToast();
    async function onLogin() {
      if (!username.value || !password.value) return;
      try {
        await client.login(username.value, password.value);
        $toast.success("Sesión iniciada");
      } catch (err: any) {
        $toast.error("Error iniciando sesión");
      } finally {
        password.value = "";
      }
    }
    client.onLogout.subscribe(() => {
      router.push({ name: "home" });
    });
    return { password, username, onLogin, token };
  },
});
</script>

<style scoped></style>
