import { Team } from "@prisma/client";
import {
  TeamMemberUser,
  TeamGroup,
  UserBasicInfo,
  TeamRole,
  Optional,
  TeamRead,
} from "@momentum/model";
import { ApiRecordset, removeProperties } from "./ApiRecordset.js";
import type { ApiClient } from "./ApiClient.js";
import { TeamGroupRecordset } from "./TeamGroupRecordset.js";
import { TeamMemberRecordset } from "./TeamMemberRecordset.js";
import { TeamCategoryRecordset } from "./TeamCategoryRecordset.js";

export interface GroupMembers {
  users: UserBasicInfo[];
  group: TeamGroup | null;
}

export class TeamRecordset extends ApiRecordset<
  TeamRead,
  "IDTeam",
  Optional<Team, "IDTeam">
> {
  constructor(teamService: TeamService) {
    super({
      client: teamService.client,
      primaryKey: "IDTeam",
      path: "team",
      newHook: () => teamService.purge(),
      updateHook: () => teamService.purge(),
      removeHook: () => teamService.purge(),
      pack: (item) => {
        if (
          item.IDTeamCategory &&
          item.TeamCategory &&
          item.IDTeamCategory !== item.TeamCategory.IDTeamCategory
        ) {
          console.warn(
            "TeamService: TeamCategory property does not match IDTeamCategory. IDTeamCategory will be used",
            item
          );
        }
        return removeProperties(item, "TeamCategory");
      },
      default() {
        return {
          TeamName: "",
          FirstDayOfWeek: 1,
          IDTeamCategory: null,
          TeamCategory: null,
        };
      },
    });
  }
}

export class TeamService {
  public currentTeam: Team | undefined;
  constructor(public readonly client: ApiClient) {}
  private getTeamsPromise: Promise<TeamRead[]> | undefined;

  async getTeams() {
    if (!this.getTeamsPromise) {
      this.getTeamsPromise = this.client
        .invoke("team")
        .getPaged<TeamRead>()
        .then((paged) => paged.data);
    }
    return await this.getTeamsPromise;
  }

  async getTeam(IDTeam: number) {
    const teams = await this.getTeams();
    return teams.find((team) => team.IDTeam === IDTeam);
  }

  public purge() {
    this.getTeamsPromise = undefined;
  }

  async getTeamMembers(
    IDTeam: number,
    IDTeamMemberRole?: TeamRole
  ): Promise<TeamMemberUser[]> {
    return this.client
      .invoke(`team/${IDTeam}/member`)
      .filter(
        (IDTeamMemberRole && {
          filters: [
            {
              field: "IDTeamRole",
              value: IDTeamMemberRole,
              matchMode: "equals",
            },
          ],
        }) ||
          undefined
      )
      .getPaged<TeamMemberUser>()
      .then((r) =>
        r.data.sort(
          // sort by group name, then by user name
          (a, b) =>
            (a.TeamGroup?.TeamGroupName || "").localeCompare(
              b.TeamGroup?.TeamGroupName || ""
            ) ||
            UserBasicInfo.format(a.User).localeCompare(
              UserBasicInfo.format(b.User)
            )
        )
      );
  }

  async getTeamMembersByGroup(IDTeam: number, IDTeamMemberRole?: TeamRole) {
    const members = await this.getTeamMembers(IDTeam, IDTeamMemberRole);
    const m = new Map<number | null, GroupMembers>();
    members.forEach((tm) => {
      let gm = m.get(tm.IDTeamGroup);
      if (gm === undefined) {
        gm = { users: [], group: tm.TeamGroup };
        m.set(tm.IDTeamGroup, gm);
      }
      gm.users.push(tm.User);
    });
    return [...m.values()];
  }

  async getUserTeamGroup(IDTeam: number, IDUser: number) {
    const members = await this.getTeamMembers(IDTeam);
    const tm = members.find((tm) => tm.User.IDUser === IDUser);
    if (!tm) {
      return null;
    }
    return tm.TeamGroup;
  }

  public newRecordset(): TeamRecordset {
    return new TeamRecordset(this);
  }
  public newGroupRecordset(IDTeam: number = 0): TeamGroupRecordset {
    return new TeamGroupRecordset(this.client, IDTeam);
  }
  public newMemberRecordset(IDTeam: number = 0): TeamMemberRecordset {
    return new TeamMemberRecordset(this.client, IDTeam);
  }

  public newTeamCategoryRecordset() {
    return new TeamCategoryRecordset(this.client);
  }
}
