import { reviver, AuthToken } from "@momentum/common";
import superagent from "superagent";

/** to be deleted once axios is out */
import axios from "axios";
import { ApiClient } from "@momentum/client";
import { ref } from "vue";

export const token = ref<AuthToken | undefined>(undefined);

function configureClient() {
  axios.defaults.timeout = 5000;
  axios.defaults.transformResponse = (data) => {
    return JSON.parse(data, reviver);
  };

  superagent.parse["application/json"] = (str: string) => {
    return JSON.parse(str, reviver);
  };

  const client = new ApiClient(window.backendUrl, superagent.agent());

  client.onNewToken.subscribe((newToken) => {
    token.value = newToken;
    localStorage.setItem("jwt", client.jwt as string);
  });

  client.onLogout.subscribe(() => {
    token.value = undefined;
    localStorage.removeItem("jwt");
  });

  const jwt = localStorage.getItem("jwt");
  if (jwt) {
    client.setJWT(jwt);
  }
  return client;
}

export const client = configureClient();
