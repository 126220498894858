import { ScheduleTemplate } from "@momentum/model";
import { ApiRecordset } from "./ApiRecordset.js";
import type { ApiClient } from "./ApiClient.js";

export class ScheduleTemplateRecordset extends ApiRecordset<
  ScheduleTemplate,
  "IDScheduleTemplate"
> {
  public IDTeam = 0;
  constructor(client: ApiClient) {
    super({
      client,
      primaryKey: "IDScheduleTemplate",
      path: {
        query: () => `team/${this.IDTeam}/schedule-template`,
        new: (item) => `team/${this.IDTeam}/schedule-template`,
        update: (item) =>
          `team/${this.IDTeam}/schedule-template/${item.IDScheduleTemplate}`,
        remove: (item) =>
          `team/${this.IDTeam}/schedule-template/${item.IDScheduleTemplate}`,
      },
      default: () => ({
        IDTeam: this.IDTeam,
        Sequence: [],
        ScheduleTemplateName: "Nueva plantilla",
      }),
    });
  }
}

export class ScheduleTemplateService {
  constructor(public readonly client: ApiClient) {}
  public newRecordset(): ScheduleTemplateRecordset {
    return new ScheduleTemplateRecordset(this.client);
  }
}
