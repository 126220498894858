import { ClockRecordSource } from "@prisma/client";
import { ApiRecordset } from "./ApiRecordset.js";
import type { ApiClient } from "./ApiClient.js";

export class ClockRecordSourceRecordset extends ApiRecordset<
  ClockRecordSource,
  "IDClockRecordSource"
> {
  constructor(client: ApiClient) {
    super({
      client,
      primaryKey: "IDClockRecordSource",
      path: "clockrecordsource",
    });
  }
}

export class ClockRecordSourceService {
  constructor(private client: ApiClient) {}
  public newRecordset() {
    return new ClockRecordSourceRecordset(this.client);
  }
}
