<template>
  <ConfirmDialog></ConfirmDialog>
  <Toast />
  <LoginDialog />
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" :title="title" />

    <transition name="layout-sidebar">
      <div
        :class="sidebarClass"
        @click="onSidebarClick"
        v-show="isSidebarVisible()"
      >
        <div
          class="flex flex-column justify-content-between"
          style="height: 100%"
        >
          <div>
            <div class="layout-logo">
              <router-link to="/">
                <img
                  alt="Logo"
                  :src="logo('logo', true)"
                  style="height: 40px; text-align: left; margin-top: 5px"
                />
              </router-link>
            </div>

            <!--<AppProfile /> -->
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
          </div>
          <div>
            <img
              alt="Logo"
              :src="logo('customer-logo')"
              style="width: 188px"
              class="m-responsive-bottom-margin"
            />
          </div>
        </div>
      </div>
    </transition>

    <div class="layout-main sm:pl-0 sm:pr-0 lg:pl-2 lg:pr-2">
      <router-view />
    </div>

    <!-- <AppConfig
      :layoutMode="layoutMode"
      :layoutColorMode="layoutColorMode"
      @layout-change="onLayoutChange"
      @layout-color-change="onLayoutColorChange"
    /> -->

    <AppFooter />
  </div>
</template>

<script lang="ts">
import AppTopBar from "./AppTopbar.vue";
//import AppProfile from "./AppProfile.vue";
import AppMenu from "./AppMenu.vue";
//import AppConfig from "./AppConfig.vue";
import AppFooter from "./AppFooter.vue";
import menuDefinition, { type RouteRecordExt } from "./menu-definition.js";
import type { MenuItem } from "./menu-definition.js";
import LoginDialog from "@/components/LoginDialog.vue";
import { ref, computed, defineComponent, watch, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import { token } from "./client.js";
import { routes } from "./router.js";

const itemMap = new Map<string | Symbol, string>();

function indexItem(item: MenuItem) {
  if (item.to) itemMap.set(item.to, item.label);
  item.items?.forEach(indexItem);
}
function indexRoute(route: RouteRecordExt) {
  if (route.name && route.label) itemMap.set(route.name, route.label);
}

menuDefinition.forEach(indexItem);
routes.forEach(indexRoute);

export default defineComponent({
  components: {
    AppTopBar: AppTopBar,
    //AppProfile: AppProfile,
    AppMenu: AppMenu,
    //AppConfig: AppConfig,
    AppFooter: AppFooter,
    LoginDialog,
  },

  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  setup() {
    const layoutMode = ref("static");
    const layoutColorMode = ref("light");
    const staticMenuInactive = ref(false);
    const overlayMenuActive = ref(false);
    const mobileMenuActive = ref(false);
    const menu = ref(menuDefinition);
    const menuActive = ref(false);
    const menuClick = ref(false);
    const title = ref("");

    const $router = useRouter();
    const $toast = useToast();
    const $appState =
      getCurrentInstance()?.appContext.config.globalProperties.$appState;
    const $primevue =
      getCurrentInstance()?.appContext.config.globalProperties.$primevue;

    const containerClass = computed(() => {
      return [
        "layout-wrapper",
        {
          "layout-overlay": layoutMode.value === "overlay",
          "layout-static": layoutMode.value === "static",
          "layout-static-sidebar-inactive":
            staticMenuInactive.value && layoutMode.value === "static",
          "layout-overlay-sidebar-active":
            overlayMenuActive.value && layoutMode.value === "overlay",
          "layout-mobile-sidebar-active": mobileMenuActive.value,
          "p-input-filled": $appState?.inputStyle === "filled",
          "p-ripple-disabled": $primevue?.config.ripple === false,
        },
      ];
    });

    const sidebarClass = computed(() => {
      return [
        "layout-sidebar",
        {
          "layout-sidebar-dark": layoutColorMode.value === "dark",
          "layout-sidebar-light": layoutColorMode.value === "light",
        },
      ];
    });

    $router.afterEach((to) => {
      menuActive.value = false;
      $toast.removeAllGroups();
      title.value =
        itemMap.get(to.path) || (to.name && itemMap.get(to.name)) || "";
    });

    $router.beforeEach((to, from, next) => {
      if (!token.value && !(to.name == "home" || to.name == "clock-in")) {
        next({ name: "home" });
        return;
      }
      next();
    });

    function logo(file: string, invert: boolean = false) {
      return (layoutColorMode.value === "dark") !== invert
        ? `assets/layout/images/${file}-white.svg`
        : `assets/layout/images/${file}.svg`;
    }
    function onWrapperClick() {
      if (!menuClick.value) {
        overlayMenuActive.value = false;
        mobileMenuActive.value = false;
      }

      menuClick.value = false;
    }
    function isDesktop() {
      return window.innerWidth > 1024;
    }

    function onMenuToggle() {
      menuClick.value = true;

      if (isDesktop()) {
        if (layoutMode.value === "overlay") {
          if (mobileMenuActive.value === true) {
            overlayMenuActive.value = true;
          }

          overlayMenuActive.value = !overlayMenuActive.value;
          mobileMenuActive.value = false;
        } else if (layoutMode.value === "static") {
          staticMenuInactive.value = !staticMenuInactive.value;
        }
      } else {
        mobileMenuActive.value = !mobileMenuActive.value;
      }

      event?.preventDefault();
    }
    function onSidebarClick() {
      menuClick.value = true;
    }
    function onMenuItemClick(event: any) {
      if (event.item && !event.item.items) {
        overlayMenuActive.value = false;
        mobileMenuActive.value = false;
      }
    }
    function onLayoutChange(newLayoutMode: string) {
      layoutMode.value = newLayoutMode;
    }
    function onLayoutColorChange(newLayoutColorMode: string) {
      layoutColorMode.value = newLayoutColorMode;
    }
    function addClass(element: HTMLElement, className: string) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    }
    function removeClass(element: HTMLElement, className: string) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    }

    function isSidebarVisible() {
      if (isDesktop()) {
        if (layoutMode.value === "static") return !staticMenuInactive.value;
        else if (layoutMode.value === "overlay") return overlayMenuActive.value;
        else return true;
      } else {
        return true;
      }
    }

    return {
      layoutMode,
      layoutColorMode,
      staticMenuInactive,
      overlayMenuActive,
      mobileMenuActive,
      menu,
      menuActive,
      menuClick,
      title,
      containerClass,
      sidebarClass,
      logo,
      onWrapperClick,
      isDesktop,
      onMenuToggle,
      onSidebarClick,
      onMenuItemClick,
      onLayoutChange,
      onLayoutColorChange,
      addClass,
      removeClass,
      isSidebarVisible,
    };
  },
});
</script>

<style lang="scss">
@import "./App.scss";
</style>
