<template>
  <div class="flex flex-wrap justify-content-start m-gap-2" :class="extraClass">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    extraClass: { type: [Object, String], required: false },
  },
  setup() {},
});
</script>
<style scoped>
.m-gap-2 {
  gap: 0.5rem 0.5rem;
}
</style>

