import {
  ScheduleEntryClockEx,
  SpecialShiftCodesMap,
  SyncInfo,
  TeamScheduleEntry,
  UserBasicInfo,
} from "@momentum/model";
import { ShiftMap } from "../ShiftsService.js";
import { UserSchedule } from "./UserSchedule.js";
import { Record } from "../recordset.js";

export class TeamSchedule {
  public readonly members: Map<number, UserSchedule>;
  constructor(
    public readonly dateInit: Date,
    public readonly dateEnd: Date,
    initialUsers: UserSchedule[],
    public readonly shifts: ShiftMap,
    public readonly specialShiftCodes: SpecialShiftCodesMap,
    public readonly IDTeam: number
  ) {
    this.members = new Map(initialUsers.map((u) => [u.user.IDUser, u]));
  }

  public user(user: UserBasicInfo): UserSchedule {
    let sched = this.members.get(user.IDUser);
    if (!sched) {
      sched = new UserSchedule(
        this.dateInit,
        this.dateEnd,
        [],
        user,
        this.shifts,
        this.specialShiftCodes,
        this.IDTeam
      );
      this.members.set(user.IDUser, sched);
    }
    return sched;
  }

  public entries(): TeamScheduleEntry[] {
    const a = [...this.members.values()].map((u) => u.entry());
    return a;
  }

  public sync(): SyncInfo<Record<ScheduleEntryClockEx>> {
    const si: SyncInfo<Record<ScheduleEntryClockEx>> = {
      modified: [],
      deleted: [],
      new: [],
    };
    for (const us of this.members.values()) {
      const ussi = us.sync();
      si.new.push(...ussi.new);
      si.modified.push(...ussi.modified);
      si.deleted.push(...ussi.deleted);
    }
    return si;
  }

  public updateDeleted(
    newRecords: Record<ScheduleEntryClockEx>[],
    deleted: number[]
  ) {
    this.members.forEach((u) => u.updateDeleted(deleted));
    newRecords.forEach((se) => this.members.get(se.IDUser)?.updateNew(se));
  }
}
