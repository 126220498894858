import { Permission } from "@momentum/model";
import type { Optional } from "@momentum/model";

import type { RouteRecordRaw } from "vue-router";
import demoMenu from "./menu-demos";

export type RouteRecordExt = RouteRecordRaw & {
  permissions?: Permission[];
  label?: string;
};
export interface MenuItem {
  label: string;
  icon: string;
  to?: string;
  route?: Optional<RouteRecordExt, "path" | "name">;
  visible?: boolean | (() => boolean);
  permissions?: Permission[];
  items?: MenuItem[];
}

const menuDefinition: MenuItem[] = [
  {
    label: "Inicio",
    icon: "pi pi-fw pi-home",
    to: "/",
    route: {
      name: "home",
      component: () => import("./pages/Home.vue"),
    },
  },
  {
    label: "Mi Horario",
    icon: "pi pi-fw pi-calendar",
    to: "/scheduleManager/my-schedule/team/date",
    permissions: [Permission.ADMIN, Permission.MY_SCHEDULE],
  },
  {
    label: "Empleados",
    icon: "pi pi-fw pi-id-card",
    to: "/user",
    permissions: [Permission.ADMIN],
    route: {
      name: "user",
      component: () => import("./pages/user/Main.vue"),
    },
  },
  {
    label: "Equipos",
    icon: "pi pi-fw pi-sitemap",
    to: "/team",
    permissions: [Permission.ADMIN],
    items: [
      {
        label: "Miembros y grupos",
        icon: "pi pi-user-plus",
        to: "/team/member",
        route: {
          name: "team-member",
          component: () => import("./pages/team/member/Main.vue"),
        },
      },
      {
        label: "Categorías",
        icon: "pi pi-building",
        to: "/team/category",
        route: {
          name: "team-category",
          component: () => import("./pages/team/category/Main.vue"),
        },
      },
    ],
    route: {
      name: "team",
      component: () => import("./pages/team/Main.vue"),
    },
  },

  {
    label: "Planificación",
    icon: "pi pi-sliders-h",
    permissions: [Permission.ADMIN],
    items: [
      {
        label: "Turnos",
        icon: "pi pi-fw pi-list",
        to: "/shiftEditor",
        route: {
          name: "shifteditor",
          component: () => import("./pages/shift/Main.vue"),
        },
      },
      {
        label: "Plantillas",
        icon: "pi pi-table",
        to: "/planning/templates",
        route: {
          name: "planning-templates",
          component: () => import("./pages/planning/templates/Main.vue"),
        },
      },
      {
        label: "Festivos",
        icon: "pi pi-calendar",
        to: "/holiday",
        route: {
          name: "holiday",
          component: () => import("./pages/holiday/Main.vue"),
        },
      },
      {
        label: "Códigos especiales",
        icon: "pi pi-tags",
        to: "/planning/special-shift-codes",
        route: {
          name: "special-shift-codes",
          component: () =>
            import("./pages/planning/special-shift-codes/Main.vue"),
        },
      },
    ],
  },
  {
    label: "Cuadrantes",
    icon: "pi pi-fw pi-calendar-plus",
    to: "/scheduleManager/team/date/days",
    permissions: [
      Permission.ADMIN,
      Permission.READ_ALL_SCHEDULES,
      Permission.WRITE_TEAM_SCHEDULE,
    ],
    items: [
      {
        label: "Anual",
        icon: "pi pi-user",
        to: "/scheduleManager/year/team/user",
      },
      {
        label: "Mensual",
        icon: "pi pi-user",
        to: "/scheduleManager/year/month/team/user",
      },
    ],
  },
  {
    label: "Registros",
    icon: "pi pi-clock",
    to: "/clockrecord",
    permissions: [Permission.ADMIN],
    route: {
      name: "clockrecord",
      component: () => import("./pages/clockrecord/Main.vue"),
    },
  },
  {
    label: "Informes",
    icon: "pi pi-fw pi-book",
    permissions: [Permission.ADMIN],
    items: [
      {
        label: "Actividad Extra",
        icon: "pi pi-clock",
        permissions: [Permission.ADMIN],
        to: "/reports/extra-time/date-init/date-end",
      },
      {
        label: "Diario",
        icon: "pi pi-calendar-minus",
        permissions: [Permission.ADMIN],
        to: "/reports/day-entries/users/date-init/date-end",
      },
    ],
  },
] as any;

if (window.ENABLE_DEMO) {
  menuDefinition.push(demoMenu);
}

export default menuDefinition;
