function localDemosFunc() {
  // webpack needs to see the imports as part of the code.
  // This is a hack so we can have a single place to list all demos.
  return [
    import("@/components/demo/YearSelectorDemo.vue"),
    import("@/components/demo/SelectorDemo.vue"),
    import("@/components/demo/ShiftSelectorDemo.vue"),
    import("@/components/demo/UserInfoDemo.vue"),
    import("@/components/demo/TeamInfoDemo.vue"),
    import("@/components/demo/LoginDemo.vue"),
    import("@/components/demo/ShiftLegendDemo.vue"),
    import("@/components/demo/ScheduleTemplateSelectorDemo.vue"),
    import("@/components/demo/ChipsDemo.vue"),
  ];
}

const findDemosRegex = /([^\/"]*)Demo\.vue/gm;
const demoImports = localDemosFunc();
const demoPaths = [...localDemosFunc.toString().matchAll(findDemosRegex)];

if (demoImports.length != demoPaths.length && window.ENABLE_DEMO) {
  console.warn(
    "Incorrect demo definitions in localDemosFunc(). Demos disabled"
  );
  window.ENABLE_DEMO = false;
}

const demos = demoPaths.map((d, i) => ({
  label: d[1],
  icon: "pi pi-fw pi-caret-right",
  to: "/localdemo/" + d[1] + "-demo",
  route: {
    name: d[1] + "-demo",
    component: () => demoImports[i],
  },
}));

export default {
  label: "Demo",
  icon: "pi pi-fw pi-map",
  items: [
    {
      label: "Local Demos",
      icon: "pi pi-fw pi-thumbs-up",
      items: demos,
    },
    {
      label: "RouteRefs",
      to: "/localdemo/RouteRefs-demo/a/b/c/d",
      icon: "pi pi-fw pi-thumbs-up",
    },
  ],
};
