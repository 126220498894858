import type { ApiClient } from "./ApiClient.js";
import { NFCAuthInfo, NFCClockin } from "@momentum/model";

export class NFCService {
  constructor(public readonly client: ApiClient) {}
  public clockIn(tagAuthInfo: NFCAuthInfo) {
    return this.client
      .invoke(`nfc/clockin`)
      .post<NFCAuthInfo, NFCClockin>(tagAuthInfo);
  }
}
