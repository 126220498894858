import type { ApiClient } from "./ApiClient.js";
import { ApiRecordset } from "./ApiRecordset.js";
import {
  isSpecialShiftCode,
  Shift,
  SpecialShiftCodesMap,
} from "@momentum/model";
import type { SpecialShiftCode } from "@prisma/client";
import { asyncComputed } from "@momentum/common";

export class ShiftMap {
  public readonly ID2Shift: Map<number, Shift>;
  public readonly Code2Shift: Map<string, Shift>;
  constructor(shifts: Shift[]) {
    this.Code2Shift = new Map(shifts.map((s) => [s.Code, s]));
    this.ID2Shift = new Map(shifts.map((s) => [s.IDShift as number, s]));
  }
  public fromCode(code: string) {
    return this.Code2Shift.get(code);
  }
  public fromID(IDShift: number) {
    return this.ID2Shift.get(IDShift);
  }
  public codes() {
    return [...this.Code2Shift.keys()];
  }
  public shifts() {
    return [...this.Code2Shift.values()];
  }
}

export class ShiftRecordset extends ApiRecordset<Shift, "IDShift"> {
  public IDTeam = 0;
  constructor(client: ApiClient) {
    super({
      client,
      primaryKey: "IDShift",
      path: {
        query: () => `team/${this.IDTeam}/shift`,
        new: (item) => `team/${this.IDTeam}/shift`,
        update: (item) => `team/${this.IDTeam}/shift/${item.IDShift}`,
        remove: (item) => `team/${this.IDTeam}/shift/${item.IDShift}`,
      },
      default: () => ({
        IDTeam: this.IDTeam,
        Intervals: [],
        Code: "",
        ShiftName: "",
      }),
    });
  }
}

export class SpecialShiftCodeRecordset extends ApiRecordset<
  SpecialShiftCode,
  "Code"
> {
  public IDTeam = 0;
  constructor(client: ApiClient, purge: () => void) {
    super({
      client,
      primaryKey: "Code",
      path: {
        query: () => `special-shift-code`,
        new: (item) => `special-shift-code`,
        update: (item) => `special-shift-code/${item.Code}`,
        remove: (item) => `special-shift-code/${item.Code}`,
      },
      default: () => ({
        Code: "",
        Description: "",
        Zero: false,
      }),
      newHook: () => purge(),
      updateHook: () => purge(),
      removeHook: () => purge(),
    });
  }
}

export class ShiftService {
  private getSpecialShiftCodesPromise:
    | Promise<SpecialShiftCodesMap>
    | undefined;

  constructor(public readonly client: ApiClient) {}
  async getShifts(IDTeam: number): Promise<Shift[]> {
    return this.client
      .invoke(`team/${IDTeam}/shift`)
      .getPaged<Shift>()
      .then((r: any) => r.data);
  }
  async getShiftMap(IDTeam: number) {
    return new ShiftMap(await this.getShifts(IDTeam));
  }
  public newRecordset() {
    return new ShiftRecordset(this.client);
  }

  async getSpecialShiftCodes(): Promise<SpecialShiftCodesMap> {
    if (!this.getSpecialShiftCodesPromise) {
      this.getSpecialShiftCodesPromise = this.client
        .invoke("special-shift-code")
        .getPaged<SpecialShiftCode>()
        .then((paged) => new Map(paged.data.map((s) => [s.Code, s])));
    }
    return this.getSpecialShiftCodesPromise;
  }

  specialShiftCodesComputed() {
    return asyncComputed(async () => await this.getSpecialShiftCodes());
  }

  async isSpecialShiftCode(code: string) {
    return isSpecialShiftCode(code, await this.getSpecialShiftCodes());
  }

  async validCode(code: string | undefined | null) {
    return Shift.validCode(code, await this.getSpecialShiftCodes());
  }

  public purgeSpecialShiftCodes() {
    this.getSpecialShiftCodesPromise = undefined;
  }

  public newSpecialShiftCodesRecordset() {
    return new SpecialShiftCodeRecordset(this.client, () => {
      this.purgeSpecialShiftCodes();
    });
  }
}
