import { FilterQuery } from "@momentum/common";
import {
  ClockRecordWithRecordSource,
  ScheduleEntryClockWithTeam,
  UserRead,
  UserWrite,
  DateRange,
} from "@momentum/model";
import { User } from "@prisma/client";
import type { ApiClient } from "./ApiClient.js";
import { ApiRecordset, removeProperties } from "./ApiRecordset.js";

export class UserRecordset extends ApiRecordset<UserRead, "IDUser", UserWrite> {
  public showDeleted: boolean = false;
  constructor(client: ApiClient) {
    super({
      client,
      primaryKey: "IDUser",
      path: {
        query: () => `user?showDeleted=${this.showDeleted}`,
        new: (item) => `user`,
        update: (item) => `user/${item.__id}`,
        remove: (item) => `user/${item.__id}`,
      },
      default() {
        return {
          UserName: "",
          LastName: "",
          FirstName: "",
          IdentificationNumber: "",
          SocialSecurityNumber: "",
          AuthInfo: null,
          Deleted: false,
        };
      },
      pack(ur) {
        if (ur.AuthInfo)
          return { ...ur, AuthInfo: removeProperties(ur.AuthInfo, "Role") };
        else return ur as any;
      },
    });
  }
}

export class UserService {
  constructor(public readonly client: ApiClient) {}
  private userCache = new Map<number, User>();
  public getUser(IDUser: number): Promise<User> {
    return this.client.invoke(`user/${IDUser}`).getSingle<User>();
  }

  public async getUserCached(IDUser: number): Promise<User | undefined> {
    let user = this.userCache.get(IDUser);
    if (!user) {
      user = await this.getUser(IDUser);
      this.userCache.set(IDUser, user);
    }
    return user;
  }

  public async findUsers(query: FilterQuery): Promise<User[]> {
    return this.client
      .invoke("user?showDeleted=false")
      .filter(query)
      .getPaged<User>()
      .then((r) => r.data);
  }

  public getScheduleUnclocked(IDUser: number, dateRange: DateRange) {
    return this.client
      .invoke(`user/${IDUser}/schedule-unclocked`)
      .dateRange(dateRange)
      .get<ScheduleEntryClockWithTeam>();
  }

  public getClockRecords(
    IDUser: number,
    dateRange: DateRange,
    unassigned: boolean
  ) {
    return this.client
      .invoke(`user/${IDUser}/clockrecord`)
      .dateRange(dateRange)
      .query({ unassigned: Number(unassigned) })
      .get<ClockRecordWithRecordSource>();
  }

  public resetPassword(IDUser: number, newPassword: string) {
    return this.client
      .invoke(`user/${IDUser}/password`)
      .postCommand({ Password: newPassword });
  }

  public newRecordset(): UserRecordset {
    return new UserRecordset(this.client);
  }
}
